import styles from "./style.module.css";
function HeaderSection() {
  return (
    <>
      <section className={`${styles["header-section"]} `}>
        <div className={`${styles["header-section-background-overlay"]}`}></div>
        <div className={`${styles["header-section-container"]}`}>
          <div className={`${styles["header-section-content"]}`}>
            <h2 className={`${styles["header-section-title"]}`}>
              What You Cannot Predict, <br />
              You Can Insure.
            </h2>
            {/* <h3 className={`${styles["section-sub-title"]}`}>
              Give yourself and all your loved one’s a financially secured and
              stress-free future.
            </h3> */}
            <br />
            <a href="#book-appointment">
              <button
                className={`${styles["header-section-content-btn"]} ${styles["animatedBouncInUp"]} ${styles["bounceInUp"]}`}
              >
                GET STARTED
              </button>
            </a>
          </div>
          <div className={`${styles["header-section-image"]}`}>
            <div
              className={`${styles["header-section-image-container"]} ${styles["animated"]} ${styles["animatedFadeInUp"]} ${styles["fadeInUp"]}  `}
            >
                <img
              src={
                process.env.REACT_APP_STATIC_URL +
                "media/wp/insurance-1.png"
              }
              alt=""
            />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeaderSection;

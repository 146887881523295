import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import commonEncode from "../commonEncode";
import { apiCall } from "../common_utilities";
import FintooLoader from "../components/FintooLoader";
import { ADVISORY_CALLBACKSERVICES_API_URL, BASE_API_URL } from "../constants";

const PaymentFailurePopup = () => {
  const [response, setResponse] = useState("");
  const [flag, setFlag] = useState(0);
  const [url, setUrl] = useState("");
  const redirecttoPayment = (url) => {
    window.location.href = url;
  };

  const getQueryParam = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  const hasQueryParams = () => {
    return window.location.search.length > 0;
  };

  const failurfunction = async (error_response) => {
    if (error_response) {
      try {
        var mail_payload = {
          userdata: {
            to: "tech.financialhospital@gmail.com",
          },
          subject: "Error occured during payment for details",
          template: JSON.stringify(error_response),
          contextvar: {},
        };
        var mail_config = {
          method: "POST",
          url: BASE_API_URL + "restapi/sendmail/",
          data: commonEncode.encrypt(JSON.stringify(mail_payload)),
        };
        var mail_res = await axios(mail_config);
        if (mail_res) {
          window.location.href = "/web/pricing";
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      window.location.href = "/web/pricing";
    }
  };

  useEffect(() => {
    if (hasQueryParams()) {
      const encodedResponse = getQueryParam("response_data");
      const flag = getQueryParam("flag");
      const url = getQueryParam("url");
      const decodedResponse = atob(encodedResponse);
      console.log('decodedResponse',decodedResponse);
      setResponse(decodedResponse);
      setFlag(flag);
      setUrl(url);
    }
  }, []);

  return (
    <div className="white-modal fn-redeem-modal">
      <div
        className="popup payment-popup schedule-meeting-popup active"
        id="payment"
      >
        <div className="popup-container" style={{ overflow: "visible" }}>
          <div className="popup-wrapper text-center">
            <div className="header-box popupHeaderimg popup-header d-flex justify-content-center">
              <img
              src={
                process.env.REACT_APP_STATIC_URL +
                "media/unsucesfull.svg"
              }
                className="popup-img "
                alt="Popup Header"
              />
            </div>
            <div className="popup-body payment-popupbody paymentfail-popupbody">
              <div className="offer-content">
                <div className="row justify-content-center">
                  <div className="col-md-12 mb-3">
                    <div className="popup-illustration">
                      <img
                        src={
                          process.env.REACT_APP_STATIC_URL +
                          "media/unsuccessful-payment.svg"
                        }
                        className="mb-4 payment_success_img"
                        alt="Payment Success Illustration"
                      />
                    </div>
                    <h2 className="mt-0 mb-0">
                      <span style={{color : "#24a7df"}} className="pink-link">Payment Failure!</span>
                    </h2>
                  </div>
                </div>
                {flag === 1 ? (
                  <button
                    className="outline-btn popup-green-btn d-block"
                    onClick={() => redirecttoPayment(url)}
                    type="button"
                  >
                    Continue
                  </button>
                ) : (
                  <button
                    className="outline-btn popup-green-btn d-block"
                    onClick={() => failurfunction(error_response)}
                    type="button"
                  >
                    Continue
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailurePopup;

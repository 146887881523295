import { useState, useEffect, useRef } from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import Signatureimg from "../../../Assets/08_signture.svg";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import Photo from "../Aadhar/Photo";
import "../Fatca/style.css";
import Back from "../../../Assets/left-arrow.png";
import axios from "axios";
import {
  DMFPAGE,
  DMF_CLIENT_REGISTRATION_API_URL,
  DMF_GENERATE_AOF_API_URL,
  DMF_UPLOADDOCUMENT_API_URL,
} from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import {
  CheckSession,
  fetchData,
  fetchEncryptData,
  getUserId,
  memberId,
} from "../../../../common_utilities";
import * as constClass from "../../../../constants";

import styled from "styled-components";

import FintooButton from "../../../HTML/FintooButton";
import { canvasPreview } from "../../../FintooCrop/canvasPreview";
import { MdDelete } from "react-icons/md";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";

import ReactCrop from "../../../HTML/ReactCrop";
import SweetAlert from "sweetalert-react/lib/SweetAlert";
// import {mergeFile,mergeBase64} from 'canvas-merge-images';
// import mergeImages from "merge-images";

const userid = memberId();

const UploadInput = styled("input")({
  display: "none",
});

const aspect = 16 / 9;
const scale = 1;

const UploadSignature = (props) => {
  const dispatch = useDispatch();
  const inputFileUpload = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [imgSrc, setImgSrc] = useState("");
  const [completedCrop, setCompletedCrop] = useState(null);
  const [preview, setPreview] = useState(false);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [rotateValue, setRotateValue] = useState(0);
  const [mainData, setMainData] = useState({});
  const showBack = useSelector((state) => state.isBackVisible);
  const [showModal, setShowModal] = useState(false);
  const [showModalfalse, setshowModalfalse] = useState(false);
  const [condition, setCondition] = useState(false);
  const [kycdetails, setKycDetails] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [oldPhoto, setOldPhoto] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [popupTitleError, setPopupTitleError] = useState("AOF REJECT");
  const [popupDescError, setPopupDescError] = useState(
    "Hi, YOUR AOF HAS BEEN REJECTED"
  );
  const [flag, setFlag] = useState(false);

  console.log("old", userDetails.bse_reg);
  useEffect(() => {
    getUserKycDetails();
    GetUserDetails();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [condition]);

  useEffect(() => {}, [condition]);

  useEffect(() => {
    loadInIt();
  }, [flag]);

  useEffect(() => {
    if (userDetails.bse_reg == "N" || userDetails.bse_reg == "") {
      clientRegistration();
    }
  }, []);

  const loadInIt = async () => {
    try {
      var payload = {
        url: constClass.USER_GET_DOCS,
        method: "post",
        data: {
          user_id: getUserId(),
        },
      };

      var response = await fetchEncryptData(payload);

      var photo = {};

      photo = response.data.filter((v) => v.doc_other_name == "Signature")[0];
      if (photo === undefined) photo = {};

      var pObj = {};
      if (Object.keys(photo).length) {
        pObj = { doc_id: photo.doc_id };
        var paylaod = {
          data: {
            user_id: getUserId(),
            file_name: photo.doc_name,
          },
          url: constClass.DOCS_READ_FILE,
          method: "post",
        };
        var response = await fetchData(paylaod);
        if (response.error_code == "100") {
          setFlag(true);
        }

        if ("data" in response && "file_url" in response.data) {
          pObj = { ...pObj, url: response.data.file_url };
          setOldPhoto({ ...pObj });
        }
      }
    } catch (e) {}
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files[0].type == "image/jpeg" ||
        e.target.files[0].type == "image/png"
      ) {
        if (e.target.files[0].size <= 2097152) {
          setCrop(undefined);
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            setImgSrc(reader.result.toString() || "")
          );
          reader.readAsDataURL(e.target.files[0]);
        } else {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: "Files up to 2MB accepted.",
              type: "error",
              autoClose: 3000,
            },
          });
          onRemoveUploaded();
        }
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Invalid file extension being uploaded.",
            type: "error",
            autoClose: 3000,
          },
        });
        onRemoveUploaded();
      }
    }
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  };

  const convertBlob = () => {
    debugger;
    console.log(imgSrc);
    fetch(imgSrc)
      .then((res) => res.blob())
      .then(async (blob) => {
        convertBase(blob);
      });
  };

  const convertBase = async (blob) => {
    if (flag) {
      setCondition(false);
      setShowModal(true);
    } else {
      const current = new Date();
      const time = current.toLocaleTimeString("en-US");
      let file = new File([blob], "filName" + time + ".png", {
        type: "image/png",
      });
      let formdata = new FormData();
      formdata.append("doc_file", file);
      formdata.append("user_id", userid);
      formdata.append("doc_type", "141");
      formdata.append("no_enc_key", "AAAA");
      formdata.append("doc_name", "Signature");
      var docs = axios({
        url: constClass.DMF_UPLOADDOCUMENT_API_URL,
        method: "POST",
        data: formdata,
      });
      var res = await docs;
      var response = res.data.error_code;

      if (response == 100) {
        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: "Signature uploaded successfully.",
            type: "success",
            autoClose: 3000,
          },
        });
        if (userDetails.residential_status == 1) {
          if (kycdetails[0].kyc_verified == 1) {
            generateAOF();
          } else if (kycdetails[0].kyc_verified == 0) {
            setCondition(false);
            setShowModal(true);
          }
        } else if (userDetails.residential_status == 2 || userDetails.residential_status == 3) {
          setCondition(false);
          suppoortmailhit();
          setShowModal(true);
        }
      }
    }
  };

  const suppoortmailhit = async () => {
    try {
      var urlmail = {
        userdata: {
          to: "support@fintoo.in"
          // "support@fintoo.in",
        },
        subject: "Action Required - New NRE/NRO Member Are Registered",
        template: "NRE_NRO_UPDATE.html",
        contextvar: {
              name: userDetails.name,
              user_id : getUserId(),
              pan:userDetails.pan,
              platform :"FIntoo",   
              mobile : userDetails.mobile,
              email:userDetails.email ,
              type:"NRE/NRO"      
        },
      };
      
      let config = {
        method: "post",
        url: constClass.DMF_SENDMAIL_API_URL,
        data: urlmail,
      };
      console.log(userDetails, "usershree");
      var res = await fetchEncryptData(config);
      console.log(res, "response mail");
    } catch (e) {
      console.log("------->", e);
    }
  };

  const clientRegistration = async () => {
    let url = constClass.DMF_CLIENT_REGISTRATION_API_URL;
    let data_sent = JSON.stringify({
      user_id: getUserId(),
      is_direct: constClass.IS_DIRECT,
    });

    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    console.log("here", response_obj);
    if (error_code == "100") {
      FATCAUpload();
    } else {
      setTimeout(() => {
        props.onNext();
      }, 1000);
    }
  };

  const FATCAUpload = async () => {
    let url = constClass.DMF_FATCA_UPLOAD_API_URL;
    let data_sent = JSON.stringify({
      user_id: getUserId(),
      is_direct: constClass.IS_DIRECT,
    });
    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    if (error_code == "100") {
      toast.success("User details updated successfully.", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000,
      });
      setTimeout(() => {
        props.onNext();
      }, 1000);
    } else {
      toast.success("User details updated successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000,
      });
      setTimeout(() => {
        props.onNext();
      }, 1000);
    }
  };

  const generateAOF = async () => {
    debugger;
    let url = constClass.DMF_GENERATE_AOF_API_URL;
    let data_sent = JSON.stringify({
      user_id: userid,
      is_direct: constClass.IS_DIRECT,
    });
    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];

    if (error_code == "100") {
      AOFImageUplode();
    } else {
      setshowModalfalse(true);
    }
  };

  const AOFImageUplode = async () => {
    let url = constClass.DMF_AOF_IMAGE_UPLOAD_API_URL;
    let data_sent = JSON.stringify({
      user_id: userid,
      is_direct: constClass.IS_DIRECT,
    });
    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    console.log("response", response);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];

    if (error_code == "100") {
      setCondition(true);
      setShowModal(true);
    } else {
      setCondition(false);
      setshowModalfalse(true);
      //setPopupDescError(response_obj["message"]);
      setPopupDescError("Please try again later, or contact customer support.");
      setPopupTitleError("Oops! Something went wrong");
    }
  };

  const GetUserDetails = async (v) => {
    try {
      var userid = getUserId();
      var payload = {
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: {
          user_id: userid,
          is_direct: constClass.IS_DIRECT,
        },
        method: "post",
      };

      var response = await fetchEncryptData(payload);
      setUserDetails(response.data);
    } catch (e) {}
  };

  const getUserKycDetails = async () => {
    var urlgetmemdata1 = {
      user_id: getUserId(),
      is_direct: constClass.IS_DIRECT,
    };

    var data = commonEncode.encrypt(JSON.stringify(urlgetmemdata1));
    var config = {
      method: "post",
      url: constClass.DMF_GET_KYC_DETAILS_API_URL,
      data: data,
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    var a = JSON.parse(response)["data"];
    setKycDetails(a);
  };

  const onRemoveUploaded = () => {
    inputFileUpload.current.value = "";
    setImgSrc("");
    setPreview(false);
  };
  const handleChange = () => {
    window.location.href = process.env.PUBLIC_URL + "/direct-mutual-fund/";
  };

  const handleChange1 = () => {
    window.location.href = process.env.PUBLIC_URL + "/direct-mutual-fund/";
  };

  const deleteOldPhoto = async () => {
    try {
      var payload = {
        url: constClass.DOCS_DELETE_FILE,
        data: {
          document_id: "" + oldPhoto.doc_id,
          user_id: getUserId(),
        },
        method: "post",
      };
      var response = await fetchData(payload);

      setOldPhoto({});
      setFlag(false)
      setOpenConfirm(false);
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: response.message, type: "success" },
      });
    } catch (e) {}
  };

  // const abc = mergeImages(oldPhoto.url,oldPhoto.url
  // )
  // console.log("merge",abc)

  return (
    <>
      <SweetAlert
        show={openConfirm}
        title="Delete photo"
        text="Are you sure?"
        onConfirm={() => {
          deleteOldPhoto();
        }}
        onCancel={() => {
          setOpenConfirm(false);
        }}
        showCancelButton
      />
      <Row className="reverse">
        <div className="ProfileImg col-12 col-md-6">
          <div>
            <img src={Signatureimg} alt="Signatureimg" />
          </div>
        </div>
        <div className=" RightPanel col-12 col-md-6">
          <div className="rhl-inner">
            {showBack == true && (
              <FintooProfileBack
                title="Signature Verfication"
                onClick={() => props.onPrevious()}
              />
            )}
            <p className="">
              Please provide your signature for verification purposes.
            </p>

            <div style={{ display: "url" in oldPhoto ? "" : "none" }}>
              <div className="whitebg-rounded img-preview-n fileContainer">
                <span
                  onClick={() => {
                    // setOldPhoto({});
                    setOpenConfirm(true);
                  }}
                >
                  <MdDelete />
                </span>
                <img
                  src={oldPhoto.url}
                  style={{
                    objectFit: "contain",
                    maxHeight: "270px",
                    maxWidth: "90%",
                  }}
                />
              </div>
              <div className="pt-4">
                <FintooButton
                  className={`d-block me-0 ms-auto`}
                  onClick={() => {
                    console.log(
                      "flag && userDetails.residential_status == 1",
                      flag && userDetails.residential_status == 1
                    );
                    flag && userDetails.residential_status == 1
                      ? generateAOF()
                      : convertBlob();
                    localStorage.removeItem("YmFua19pZA==");
                    localStorage.removeItem("bmF2RHluYW1pY05leHQ=");
                    localStorage.removeItem("bmF2RHluYW1pY1ByZXY=");
                    localStorage.removeItem("cG9sX3N0YXQ=");
                    localStorage.removeItem("dGF4X3Jlcw==");
                    localStorage.removeItem("doc");
                  }}
                  title={"Next"}
                />
              </div>
            </div>

            <div style={{ display: "url" in oldPhoto ? "none" : "block" }}>
              <div style={{ display: imgSrc.trim() == "" ? "block" : "none" }}>
                <UploadInput
                  onChange={(e) => onSelectFile(e)}
                  accept="image/*"
                  id="inputFileUpload"
                  ref={inputFileUpload}
                  type="file"
                />
                <div className="dlc-bx-upload  px-5">
                  <div className="dlc-bx">
                    <div className="pnf-img-bx" role="button">
                      <label htmlFor="inputFileUpload" role="button">
                        <img
                          src={require("../../../../Assets/Images/file-upload.png")}
                        />
                        <p>Upload</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  Boolean(preview) == false && imgSrc.trim() != ""
                    ? ""
                    : "fakeHide"
                }`}
              >
                <div className="new-bg-white">
                  <ReactCrop
                    onDelete={() => {
                      setImgSrc("");
                      inputFileUpload.current.value = "";
                    }}
                    image={imgSrc.trim()}
                    maxWidth={250}
                    maxHeight={200}
                    onCrop={(v) => {
                      setImgSrc(v);
                      setPreview(true);
                    }}
                  />
                </div>
              </div>

              <div style={{ display: Boolean(preview) ? "" : "none" }}>
                <div className="fileContainer d-flex justify-content-center align-items-center img-preview-n">
                  <span onClick={() => onRemoveUploaded()}>
                    <MdDelete />
                  </span>
                  <img
                    src={imgSrc}
                    style={{
                      objectFit: "contain",
                      maxHeight: "270px",
                      maxWidth: "90%",
                    }}
                  />
                </div>

                <div className=" pt-4 mt-4">
                  <FintooButton
                    className={`d-block me-0 ms-auto`}
                    onClick={() => {
                      convertBlob();
                      localStorage.removeItem("YmFua19pZA==");
                      localStorage.removeItem("bmF2RHluYW1pY05leHQ=");
                      localStorage.removeItem("bmF2RHluYW1pY1ByZXY=");
                      localStorage.removeItem("cG9sX3N0YXQ=");
                      localStorage.removeItem("dGF4X3Jlcw==");
                      localStorage.removeItem("doc");
                    }}
                    title={"Next"}
                  />
                </div>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
      </Row>
      <Modal
        backdrop="static"
        size="lg"
        centered
        show={showModal}
        className="profile-popups sign-popup"
        onHide={() => {
          setShowModal(false);
        }}
      >
        {/* <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">Passport front</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="modal-body-box">
            <h2 className="pt-3 pb-0 text-center">Profile Completed</h2>
            {condition === true && (
              <>
                <div className="pt-3 pb-3 ">
                  <div className="img-box9 pt-4 inv-sign-border text-center">
                    <img
                      className="img-fluid inv-img-86"
                      src={require("../../../../Assets/Images/temp_img_8865.jpg")}
                    />
                  </div>
                </div>
                <h3 className="pt-2 pb-0 text-center ">
                  Hi, congratulations your profile has been completed
                </h3>
                <div className="pb-3 pt-2">
                  <FintooButton
                    onClick={() => handleChange()}
                    title={"Start Investment"}
                  />
                </div>
              </>
            )}
            {condition === false && (
              <>
                <div className="pt-3 pb-3 ">
                  <div className="img-box9 pt-4 inv-sign-border text-center">
                    <img
                      className="img-fluid inv-img-iqc"
                      src={require("../../../../Assets/Images/temp_img_548.jpg")}
                    />
                  </div>
                </div>
                <h3 className="pt-2 pb-0 text-center ">
                  Hi, congratulations your profile has been completed. <br /> We
                  require 24 to 48 hours for KYC verification. Meanwhile, you
                  can explore other Fintoo products.
                </h3>
                <div className="pb-3 pt-3">
                  <FintooButton
                    onClick={() => {
                      handleChange1();
                    }}
                    title={"Continue"}
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        size="lg"
        centered
        show={showModalfalse}
        className="profile-popups sign-popup"
      >
        <Modal.Body>
          <div className="modal-body-box">
            <h2 className="pt-3 pb-0 text-center">{popupTitleError}</h2>

            <>
              <div className="pt-3 pb-3 ">
                <div className="img-box9 pt-4 inv-sign-border text-center">
                  <img
                    className="img-fluid inv-img-86"
                    src={require("../../../../Assets/Images/temp_img_8865.jpg")}
                  />
                </div>
              </div>
              <h3 className="pt-2 pb-0 text-center ">{popupDescError}</h3>
              <div className="pb-3 pt-3">
                <FintooButton
                  onClick={() => {
                    handleChange1();
                  }}
                  title={"Continue"}
                />
              </div>
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadSignature;

import React, { useEffect, useState } from "react";
import FaqSection from "../components/HTML/RetirementPlanning/FaqSection";
import FinancialReport from "../components/HTML/RetirementPlanning/FinancialReport";
import HeaderSection from "../components/HTML/RetirementPlanning/HeaderSection";
import HowWeWorkSection from "../components/HTML/RetirementPlanning/StepsFinancialPlan";
import YoutubevideoSection from "../components/HTML/RetirementPlanning/YoutubevideoSection";
import SecuritySection from "../components/HTML/SecuritySection";
import Fullpage from "../components/Layout/Fullpage";
import WealthContactForm from "../components/WealthManagementContactForm";
import backImg from "../components/Layout/Fullpage/assets/advisor-min.jpg";
import { servicesTypes } from "../components/WealthManagementContactForm/model";
import AppointmentBox from "../components/Pages/Calendly";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const RetirementPlanning = () => {
  const [show, SetShow] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [pageurl, setPageurl] = React.useState();
  const [utmSource, setUtmSource] = useState(26);
  const [tagval, setTagval] = useState(null);
  useEffect(() => {
    function extractParametersFromURL() {
      // const urlSearchParams = new URLSearchParams(new URL(url).search);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const utmSource = urlSearchParams.get('utm_source');
      const tagval = urlSearchParams.get('tags');
      setPageurl(location.pathname);
      setUtmSource(utmSource);
      setTagval(tagval);
    }
    extractParametersFromURL();
    window.addEventListener('popstate', extractParametersFromURL);
    return () => {
      window.removeEventListener('popstate', extractParametersFromURL);
    };
  }, []);

  return (
    <Fullpage>
      <HeaderSection />
      <HowWeWorkSection />
      <FinancialReport />
      <YoutubevideoSection />
      <SecuritySection />
      <section style={{

      }} id="book-appointment">

        {utmSource && tagval ? (
          <AppointmentBox
            extraParams={{ "utm_source": utmSource, "service": 98 }}
            eventCode={tagval}
            serviceName="Retirement Planning"
            eventUrl={"https://calendly.com/fintoo/15-minutes-consultation-call-retirement-planning?hide_event_type_details=1"}
            planId = "20"
          />
        ) : (
          <AppointmentBox
            eventCode={'Callback_mintyApp_10'}
            serviceName="Retirement Planning"
            eventUrl={"https://calendly.com/fintoo/15-minutes-consultation-call-retirement-planning?hide_event_type_details=1"}
            planId = "20"
          />
        )}
      </section>
      <FaqSection />
    </Fullpage>
  );
};
export default RetirementPlanning;

import React from "react";
import Loader from "../Assets/Images/fintoo_loader_Green.gif";

const FintooLoader = (props) => {
    return (
        <>
            {props.isLoading  ? (<div className="fintoo-loader">
                <img  src={process.env.PUBLIC_URL + "/static/media/fintoo_loader_Green.gif"} /> 
                <div><p style={{textAlign: 'center'}}>Please wait...</p></div>
            </div>) : <></>}
        </>
    );
}
export default FintooLoader;
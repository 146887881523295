import React, { useRef, useEffect, useState } from "react";
import hdfc from "../Assets/hdfc.png";
import Rupee from "../Assets/Rupee.png";
import Share from "../Assets/share.png";
import bookmark from "../../Assets/Images/bookmark.png";
import star from "../Assets/star.png";
import Tab from "./TabData";
import Home from "../Assets/home.png";
import Arrow from "../Assets/arrow.png";
import DownArrow from "../Assets/down-arrow.png";
import ICICI from "../Assets/01_icici.png";
import { useNavigate } from "react-router-dom";
import RiskMeter from "./Graph/RiskMeter";
import Splinechart from "./Graph/Splinechart";
import SIP_Lumpsum from "./SIP_Lumpsum";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Allocation from "./Allocation.jsx";
import { Link, useParams } from "react-router-dom";
import TabData from "./TabData";
import Swal from "sweetalert2";
import axios from "axios";
import information from "../../Assets/Images/information.png";
import ReactTooltip from "react-tooltip";
import {
  FaRegBookmark,
  FaStar,
  FaShareAlt,
  FaAngleUp,
  FaAngleDown,
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import FintooInlineLoader from "../FintooInlineLoader";
import MainLayout from "../Layout/MainLayout";
import FintooShareBox from "../HTML/FintooShareBox/FintooShareBox";
import share from "../../Assets/Images/share.png";
import closeicon from "../../Assets/Images/closeicon.svg";
import { DMF_BASE_URL, LOGIN_PAGE_MUTUAL_FUND_LIST } from "../../constants";
import { useSearchParams } from "react-router-dom";
import commonEncode from "../../commonEncode";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import {
  loginRedirectGuest,
  FV,
  indianRupeeFormat,
  formatPrice,
  getUserId,
  defaultamclogo,
} from "../../common_utilities";
import AddToWish from "../AddToWish";
import InputSlider from "../HTML/Slider/InputSlider";
import GuestLayout from "../Layout/GuestLayout";
import FintooLoader from "../FintooLoader.js";

// GrShareOption
const successAlert = () => {
  Swal.fire({
    title: "",
    text: "Added to Wishlist!",
    icon: "success",
  });
};
const RemoveAlert = () => {
  Swal.fire({
    title: "",
    text: "Removed from Wishlist!",
    icon: "success",
  });
};

const getDimensions = (ele) => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const MutualFund = () => {
  const urlObject = useParams();
  const MAX = 40;
  const MAX1 = 100;
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [productDetail, setProductDetail] = useState([]);
  const [amount, setAmount] = useState([]);
  const [show5funds, setShow5Funds] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [compareArray, setCompareArray] = useState([]);
  const [showShare, setShowShare] = useState(false);
  const [breakWord, setBreakWord] = useState(0);
  const [error, setError] = useState(false);
  const [rate, setRate] = useState(0);
  const [year, setYear] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const userid = getUserId();

  const [expectedReturns, setExpectedReturns] = useState(0);
  const [expectedDuration, setExpectedDuration] = useState(0);
  const [additionalload, setadditionalload] = useState([]);
  const [expectedResult, setExpectedResult] = useState(0);
  const [calculatorData, setCalculatorData] = useState({ amount: 0, expectedDuration: 3, expectedReturns: 40, investedAmount: 0 });
  const schemeslug = useParams();
  const [exitLoadPer, setExitLoadPer] = useState();
  const scheme_slug = schemeslug.slug;

  const riskArray = [
    { type: "Low Risk", value: 0.07 },
    { type: "Low to Moderate Risk", value: 0.24 },
    { type: "Moderate Risk", value: 0.5 },
    { type: "Moderately High risk", value: 0.58 },
    { type: "High Risk", value: 0.78 },
    { type: "Very High Risk", value: 0.95 },
  ];
  const header = document.querySelector(".sticky");
  const getHeaderHeight = () => {
    return header.offsetHeight;
  };
  const scrollTo = (targetElement) => {
    // Calculate the header height dynamically
    const headerHeight = getHeaderHeight() + 89;

    // Calculate the target element's position relative to the viewport
    const elementPosition = targetElement.getBoundingClientRect().top;

    // Calculate the scroll position, considering the header height
    const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

    // Scroll to the calculated position smoothly
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  document.querySelectorAll(".header_link").forEach((button) => {
    button.addEventListener("click", () => {
      const sectionId = button.getAttribute("data-section-id");
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        scrollTo(targetElement);
      }
    });
  });
  const navigate = useNavigate();
  var lowUnit, highUnit, lowUnitPercent, highUnitPercent, exitloadper;

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-IN", {
      currency: "INR",
    }).format(value);

  function numDifferentiation(value) {
    if (!value) {
      return 0;
    }
    var val = Math.abs(value);
    if (val >= 10000000) {
      val = numberFormat((val / 10000000).toFixed(2)) + " Cr";
    } else if (val >= 100000) {
      val = numberFormat((val / 100000).toFixed(2)) + " Lac";
    } else if (val >= 10000) {
      val = numberFormat((val / 10000).toFixed(2)) + " k";
    }
    return val;
  }

  React.useEffect(function () {
    window.scrollTo(0, 0);
    document.body.classList.add("page-mutualfund-details");
    onLoadInIt();
    return () => {
      document.body.classList.remove("page-mutualfund-details");
    };
  }, []);

  const handleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  var usid = getUserId();

  const onLoadInIt = async () => {
    try {
      setIsLoading(true);

      var urldata = { scheme_slug: scheme_slug, scheme_code: scheme_slug };
      if (getUserId() != null) {
        urldata.user_id = getUserId();
      }
      var data = commonEncode.encrypt(JSON.stringify(urldata));

      var config = {
        method: "post",
        url: DMF_BASE_URL + "api/scheme/getschemedetail",
        data: data,
      };

      var res = await axios(config);

      var response = commonEncode.decrypt(res.data);
      var x = JSON.parse(response);
      if (x.error_code == "102") {
        setIsLoading(false);
      }
      // setadditionalload(JSON.parse(response)["data"][0]['fund_detail']['additional_exit_load']);
      setProductDetail(JSON.parse(response)["data"]);
      loadDataForCalculator(JSON.parse(response)["data"]);

      if (Boolean(JSON.parse(response)["data"][0].wishlist_key)) {
        setIsInWishlist(true);
      }
      setIsLoading(false);
    } catch (e) {
      setError(true);
    }
  };
  const toggleWishList = async () => {
    try {
      var wishlistData = { scheme_slug: scheme_slug, user_id: userid };
      const data = commonEncode.encrypt(JSON.stringify(wishlistData));
      var config = {
        method: "post",
        data: data,
      };
      if (isInWishlist) {
        // remove
        config.url = DMF_BASE_URL + "api/wishlist/delete";
        RemoveAlert();
      } else {
        // add
        config.url = DMF_BASE_URL + "api/wishlist/add";
        successAlert();
      }

      var res = await axios(config);
      setIsInWishlist(!isInWishlist);
    } catch (e) {}
  };

  const CheckSessionactive = () => {
    // loginRedirectGuest();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  const getBackgroundSize = () => {
    return { backgroundSize: `${(value * 100) / MAX}% 100%` };
  };
  const getBackgroundSizeYear = () => {
    return { backgroundSize: `${(value1 * 100) / MAX1}% 100%` };
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", isStickyNav);
    return () => {
      window.removeEventListener("scroll", isStickyNav);
    };
  }, []);
  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {};

  const isStickyNav = (e) => {
    const header = document.querySelector(".sticky ");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 259
        ? header.classList.add("fixed_top_Nav")
        : header.classList.remove("fixed_top_Nav");
    }
  };
  const [visibleSection, setVisibleSection] = useState(null);
  const [mobilePurchase, setMobilePurchase] = useState("SIP");

  const OverviewRef = useRef(null);
  const FundDetailsRef = useRef(null);
  const CompareRef = useRef(null);
  const AssetAlloRef = useRef(null);
  const TopHoldRef = useRef(null);
  const RiskMeasureRef = useRef(null);
  const OthersRef = useRef(null);

  const sectionRefs = [
    { section: "Overview", ref: OverviewRef },
    { section: "FundDetails", ref: FundDetailsRef },
    { section: "Compare", ref: CompareRef },
    { section: "AssetAllo", ref: AssetAlloRef },
    { section: "TopHold", ref: TopHoldRef },
    { section: "RiskMeasure", ref: RiskMeasureRef },
    { section: "Others", ref: OthersRef },
  ];

  useEffect(() => {
    function handleResize() {
      var ww = window.innerWidth;

      switch (true) {
        case ww < 780.1:
          setBreakWord(100);
          break;
        case ww < 1280.1:
          setBreakWord(100);
          break;
        case ww < 1920.1:
          setBreakWord(100);
          break;
        default:
          setBreakWord(100);
          break;
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 250;
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });
      if (document.querySelector("header") == null) return;
      // console.log(
      //   "yyy77--------->",
      //   selected,
      //   document.querySelector("header").getBoundingClientRect().height
      // );
      if (document.querySelector(".sticky.fixed_top_Nav") != null) {
        document.querySelector(".sticky.fixed_top_Nav").style.top =
          document.querySelector("header").getBoundingClientRect().height +
          "px";
      }
      document.querySelectorAll(".dv-fixed-menu").forEach((v) => {
        // console.log("v------->", v);
        v.classList.remove("selected");
      });
      if (selected && selected.section) {
        document
          .querySelector(".dv-" + selected.section)
          .classList.add("selected");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [style, setStyle] = useState("cont");
  const [style2, setStyle2] = useState("cont");
  const [isInWishlist, setIsInWishlist] = useState(null);
  const [isInShare, setIsInShare] = useState(null);

  const changeStyle2 = () => {
    setIsInShare(!isInShare);
    setStyle2("cont2");
  };

  const changeStyle = () => {
    setIsInWishlist(!isInWishlist);
    setStyle("cont3");
  };
  React.useEffect(() => {
    if (isInWishlist === true) {
    } else if (isInWishlist === false) {
    }
  }, [isInWishlist]);

  const [state, setState] = useState({ x: 100 });
  const [state1, setState1] = useState({ x: 100 });

  const handleChooseCompare = (v) => {
    var newArray = compareArray;
    if (newArray.findIndex((x) => x.scheme_slug == v.scheme_slug) > -1) {
      newArray.splice(newArray.indexOf(v), 1);
    } else {
      if (newArray.length < 2) {
        newArray.push(v);
      } else 
       {
        if((!toast.isActive('limitexceed')))
        {
          toast.error("You cannot select more than two funds.", {
            position: "bottom-left",
            toastId :"limitexceed"
          
          });
        }
       
      }
    }
    setCompareArray([...newArray]);
  };

  const openComparePage = () => {
    var mainSchemeCode = productDetail[0].Overview.scheme_slug;
    localStorage.setItem(
      "schemecode",
      [mainSchemeCode, ...compareArray.map((v) => v.scheme_slug)].join(",")
    );
    navigate(process.env.PUBLIC_URL + "/direct-mutual-fund/Compare");
  };
  const loadDataForCalculator = (p) => {
    if (Boolean(p[0].Overview.return_3yr)) {
      setCalculatorData(prev=> ({ ...prev, expectedDuration: 3, expectedReturns: Math.round(p[0].Overview.return_3yr * 1)}));
      // setExpectedReturns(Math.round(p[0].Overview.return_3yr * 1));
      // setExpectedDuration(3);
    } else if (Boolean(p[0].Overview.return_1yr)) {
      setCalculatorData(prev=> ({ ...prev, expectedDuration: 1, expectedReturns: Math.round(p[0].Overview.return_1yr * 1)}));
      // setExpectedReturns(Math.round(p[0].Overview.return_1yr * 1));
      // setExpectedDuration(1);
    }
  };
  const onAmountChange = (v) => {
    console.log('nil977867', v);
    setCalculatorData(prev=> ({...prev, amount: Number(v.amount), type: v.type}));
  };
  useEffect(() => {
    let newData = calculatorData;
    // if (newData.amount == 0) return;
    if (newData.type == "sip") {
      newData.expectedResult = FV(
        newData.expectedReturns / 100 / 12,
        newData.expectedDuration * 12,
        newData.amount * -1,
        null,
        1
      );
      newData.investedAmount = Number(newData.amount) * newData.expectedDuration * 12;
      newData.estReturns = Math.round(
        Number(newData.expectedResult) - 12 *
          newData.expectedDuration *
          newData.amount
      );
    } else {
      newData.expectedResult = FV(
        newData.expectedReturns / 100,
        newData.expectedDuration,
        null,
        newData.amount * -1,
        0
      );
      newData.investedAmount = newData.amount;
      newData.estReturns = Math.round(Number(newData.expectedResult) - newData.amount);
    }
    setCalculatorData({ ...newData });
  }, [calculatorData?.amount, calculatorData?.expectedReturns, calculatorData?.expectedDuration, calculatorData?.type]);

  // function conditionadditionalexitload()
  // {
  //   if(additionalload.Additional_LowBreakpoint *1 ==0 &&  additionalload.Additional_HighBreakpoint * 1 ==10)
  //   {
  //     if(additionalload.Additional_BreakpointUnit*1 >10){
  //       return true;
  //     }
  //   }
  // }

  // function conditionadditionalexitload1()
  // {
  //   if(additionalload.Additional_LowBreakpoint *1 == 10 &&  additionalload.Additional_HighBreakpoint * 1 == 0)
  //   {
  //     if(additionalload.Additional_BreakpointUnit *1 >10)
  //     {
  //       return true;
  //     }
  //   }
  // }

  // function conditionadditionalexitload2()
  // {
  //   if(additionalload.Additional_LowBreakpoint *1==0 && additionalload.Additional_HighBreakpoint *1 ==0)
  //   {
  //     return true;
  //   }
  // }

  const [showBuyModal, setShowBuyModal] = useState(false);

  console.log("nil12", isLoading);

  return (
    <GuestLayout>
      <FintooLoader isLoading={isLoading} />
      <div className="classList h-100 mf-detail-dv">
        <Container>
          <Row>
            <div className="col-12">
              {error && (
                <h2 style={{ display: "flex", justifyContent: "center" }}>
                  404 NO DATA FOUND.
                </h2>
              )}
              {/* <FintooInlineLoader isLoading={isLoading} /> */}
            </div>
            {productDetail.map((item) => (
              <>
                <div className="LeftSection col-12 col-md-8">
                  <div className="pe-lg-2">
                    <div className="HeaderFix">
                      <Row>
                        <Col sm={9}>
                          <div className="headerbx">
                            <span>
                              {console.log('item', item)}
                              <img
                                id={'amc_img'}
                                style={{ width: "40px", height: "40px" }}
                                className="amc_logo rounded-circle"
                                src={`${process.env.PUBLIC_URL}/static/media/companyicons/${item.Overview.amc_code}.png`}
                                alt=""
                                onError={()=> {
                                  document.getElementById('amc_img').setAttribute('src', defaultamclogo());
                                }}
                              />
                            </span>
                            <h4 className="FundName">
                              {item.Overview.scheme_name.length > breakWord
                                ? item.Overview.scheme_name.substr(
                                    0,
                                    breakWord
                                  ) + "..."
                                : item.Overview.scheme_name}
                            </h4>
                          </div>
                          <Row className="">
                            <Col sm={6} className="title-bottom-bx">
                              <div className="title-bottom tb-gap">
                                <p>{item.Overview.fintoo_category}</p>
                                <p>|</p>
                                <p>{item.Overview.risk}</p>
                                <p>|</p>
                                <p className="bottom-starrating-container">
                                  <p>{item.Overview.star_rating}</p>
                                  <p>
                                    <FaStar style={{ color: "#FFBF00" }} />
                                  </p>
                                </p>
                              </div>
                            </Col>
                            <Col sm={6} className="title-bottom-bx">
                              <div className="row title-bottom title-bottom-rt">
                                <div className="col-6 FunSubDetails">
                                  <p className="Text">Corpus</p>
                                  <p className="fundCrVal">
                                    {numDifferentiation(
                                      item.Overview.aum_total.toFixed(2)
                                    )}
                                  </p>
                                  <p className="Text">
                                    {item.Overview.aum_date !== null ? (
                                      <i>
                                        (As on{" "}
                                        {moment(item.Overview.aum_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                        )
                                      </i>
                                    ) : (
                                      <i>( NA )</i>
                                    )}
                                  </p>
                                </div>
                                <div className="col-6 FunSubDetails">
                                  <p className="Text">Exp. ratio</p>
                                  <p className="fundCrVal">
                                    {item.Overview.expratio}%
                                  </p>
                                  <p className="Text">
                                    {item.Overview.expratio_date !== null ? (
                                      <i>
                                        (As on{" "}
                                        {moment(
                                          item.Overview.expratio_date
                                        ).format("DD-MM-YYYY")}
                                        )
                                      </i>
                                    ) : (
                                      <i>( NA )</i>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={3}>
                          <div className=" mobileNav">
                            <div className="box-88">
                              <div className="LatestNavText">
                                <p style={{ textAlign: "right" }}>Latest Nav</p>
                              </div>
                              <div className="ValueFund">
                                <span className="FundPrice">
                                  <span>₹</span>{" "}
                                  {numberFormat(item.Overview.navrs)}
                                </span>
                                <span className="DiffLine"></span>
                                <span
                                  className="FundPlusminusVal"
                                  style={{
                                    color:
                                      item.Overview.net_change < 0
                                        ? "red"
                                        : "green" &&
                                          item.Overview.net_change == 0
                                        ? "black"
                                        : "green",
                                  }}
                                >
                                  {item.Overview.net_change > 0 ? "+" : ""}
                                  {item.Overview.net_change}%
                                </span>
                              </div>
                            </div>

                            <div className="FundIcon">
                              <div
                                className="changeColor"
                                onClick={() => {
                                  setShowShare((v) => !v);
                                }}
                              >
                                {showShare ? (
                                  <MdClose
                                    style={{ fontSize: "20px" }}
                                    className={"cont"}
                                  />
                                ) : (
                                  <FaShareAlt
                                    style={{ fontSize: "18px" }}
                                    className={"cont"}
                                  />
                                )}
                              </div>
                              {showShare && <FintooShareBox />}

                              <div
                                className="changeColor"
                                onClick={changeStyle}
                              >
                                <AddToWish
                                  value={isInWishlist}
                                  scheme_slug={item.Overview.scheme_slug}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="sticky ">
                      <h4 className="FundName ">
                        {item.Overview.scheme_name.length > breakWord
                          ? item.Overview.scheme_name.substr(0, breakWord) +
                            "..."
                          : item.Overview.scheme_name}
                      </h4>
                      <div
                        className="header stckymenu d-none d-md-block"
                        ref={OverviewRef}
                      >
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-Overview header_link ${
                            visibleSection === "Overview" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(OverviewRef.current);
                          }}
                        >
                          Overview
                        </button>
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-FundDetails header_link ${
                            visibleSection === "FundDetails" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(FundDetailsRef.current);
                          }}
                        >
                          Fund&nbsp;Details
                        </button>
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-Compare header_link ${
                            visibleSection === "Compare" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(CompareRef.current);
                          }}
                        >
                          Compare
                        </button>
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-AssetAllo header_link ${
                            visibleSection === "AssetAllo" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(AssetAlloRef.current);
                          }}
                        >
                          Asset&nbsp;Allocation
                        </button>
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-TopHold header_link ${
                            visibleSection === "TopHold" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(TopHoldRef.current);
                          }}
                        >
                          Top&nbsp;Holdings
                        </button>
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-RiskMeasure header_link ${
                            visibleSection === "RiskMeasure" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(RiskMeasureRef.current);
                          }}
                        >
                          Risk&nbsp;Measures
                        </button>
                        <button
                          type="button"
                          className={`dv-fixed-menu dv-Others header_link ${
                            visibleSection === "Others" ? "selected" : ""
                          }`}
                          onClick={() => {
                            scrollTo(OthersRef.current);
                          }}
                        >
                          Others
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <div className="content details-section">
                        <div
                          className="section"
                          id="Overview"
                          ref={OverviewRef}
                        >
                          <div className="overviewDetails">
                            <div className="row">
                              <div className="col-12 col-md-6 colleft1">
                                <div className="px-lg-2">
                                  <p className="bxheader-name">Nav Trend</p>
                                  <div className="Cagr">
                                    <div className="NavVal">
                                      <p className="ow-subtitle">6 Months</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.nav_6m === "" ||
                                        item.Overview.nav_6m === null
                                          ? "-"
                                          : "₹ " +
                                            numberFormat(
                                              (
                                                1 * item.Overview.nav_6m
                                              ).toFixed(2)
                                            )}
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">1 Year</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.nav_1yr === "" ||
                                        item.Overview.nav_1yr === null
                                          ? "-"
                                          : "₹ " +
                                            numberFormat(
                                              (
                                                1 * item.Overview.nav_1yr
                                              ).toFixed(2)
                                            )}
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">3 Years</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.nav_3yr === "" ||
                                        item.Overview.nav_3yr === null
                                          ? "-"
                                          : "₹ " +
                                            numberFormat(
                                              (
                                                1 * item.Overview.nav_3yr
                                              ).toFixed(2)
                                            )}
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">5 Years </p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.nav_5yr === "" ||
                                        item.Overview.nav_5yr === null
                                          ? "-"
                                          : "₹ " +
                                            numberFormat(
                                              (
                                                1 * item.Overview.nav_5yr
                                              ).toFixed(2)
                                            )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 col-md-6 colright1">
                                <div className="px-lg-2">
                                  <p className="bxheader-name">
                                    Performance History (CAGR)
                                  </p>
                                  <div className="Cagr">
                                    <div className="NavVal">
                                      <p className="ow-subtitle">3 M</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.return_3m}%
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">6 M</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.return_6m}%
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">1 Y</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.return_1yr}%
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">3 Y</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.return_3yr}%
                                      </p>
                                    </div>
                                    <div className="NavVal">
                                      <p className="ow-subtitle">5 Y</p>
                                      <p className="RsVal">
                                        {" "}
                                        {item.Overview.return_5yr}%
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="OverGraph">
                            <Splinechart overview={item.Overview} />
                          </div>
                        </div>
                        <div
                          className="section"
                          id="FundDetails"
                          ref={FundDetailsRef}
                        >
                          <div>
                            <h4 className="section-heading">Fund Details</h4>
                            <div className="BottomLine"></div>
                            <div className="FundDetail">
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/saving-money.png")}
                                      alt="Fund House"
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">Fund House</span>
                                </div>
                                <div className="inner-item-details-top-bx">
                                  <p className="FundNames">
                                    {item.fund_detail.amc_name}
                                  </p>
                                </div>
                              </div>
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/launch_date.png")}
                                      alt="Launch Date"
                                      width={"30px"}
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">
                                    Launch Date
                                  </span>
                                </div>
                                <div className="inner-item-details-top-bx">
                                  <p className="FundNames">
                                    {moment(
                                      item.fund_detail.launch_date
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/lock.png")}
                                      alt="Lock-in-Period"
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">
                                    Lock-in-Period
                                  </span>
                                </div>
                                <p className="FundNames">
                                  {item.fund_detail.locking_period * 1 > 0 ? 
                                    item.fund_detail.locking_period + " Years"
                                  : "No lock-in"}
                                </p>
                              </div>
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/benchmark.png")}
                                      alt="Scheme Benchmark"
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">
                                    Scheme Benchmark
                                  </span>
                                </div>
                                <div>
                                  <p className="FundNames">
                                    {item.fund_detail.scheme_benchmark !== ""
                                      ? item.fund_detail.scheme_benchmark
                                      : "-"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="BottomLine"></div>
                            <div className="FundDetail">
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/pay-day.png")}
                                      alt="Min SIP Invt"
                                      width={"30px"}
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">
                                    Min SIP Invt
                                  </span>
                                </div>
                                <p className="FundNames">
                                  {" "}
                                  {Boolean(
                                    item.fund_detail.min_sip_investment * 1
                                  )
                                    ? indianRupeeFormat(
                                        item.fund_detail.min_sip_investment * 1,
                                        0
                                      )
                                    : "-"}
                                </p>
                              </div>
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/01_min_lumpsump.png")}
                                      alt="Min Lumpsum Invt"
                                      width={"30px"}
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">
                                    Min Lumpsum Invt
                                  </span>
                                </div>
                                <p className="FundNames">
                                  ₹{" "}
                                  {numberFormat(
                                    item.fund_detail.min_lumpsump_invest
                                  )}
                                </p>
                              </div>
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/schme_type.png")}
                                      alt="Scheme Type"
                                    />
                                  </span>{" "}
                                  <span className="mobileFund">
                                    Scheme Type
                                  </span>
                                </div>
                                <p className="FundNames">
                                  {item.fund_detail.scheme_type}
                                </p>
                              </div>
                              <div className="inner-item-details">
                                <div className="inner-item-details-top-bx">
                                  <span>
                                    <img
                                      src={require("../Assets/exit_load.png")}
                                      alt="Exit Load"
                                    />
                                  </span>
                                  <span className="mobileFund">
                                    Exit Load
                                    {item.fund_detail.exit_load.length > 1 && (
                                      <sup>
                                        <span className="info">
                                          <img
                                            style={{
                                              width: "12px",
                                              height: "12px",
                                              marginLeft: "10px",
                                            }}
                                            data-tip
                                            data-for="ExitLoad"
                                            data-event-off
                                            data-title=""
                                            src={information}
                                          />
                                        </span>
                                      </sup>
                                    )}
                                  </span>

                                  <ReactTooltip
                                    id="ExitLoad"
                                    place="top"
                                    className="Tool"
                                    effect="solid"
                                    style={{
                                      height: "200px !important",
                                    }}
                                  >
                                    <ol className="NameData">
                                      {/* {item.fund_detail.additional_exit_load} */}
                                      {item.fund_detail.additional_exit_load
                                        .length > 0
                                        ? item.fund_detail.additional_exit_load.replace(
                                            "0.0000%",
                                            "Nil"
                                          )
                                        : "-"}
                                      {/* {item.fund_detail.additional_exit_load.replace("0.0000%","Nil")} */}
                                    </ol>
                                  </ReactTooltip>
                                </div>
                                <div>
                                  <p className="FundNames">
                                    {item.fund_detail.exit_load.length > 0
                                      ? item.fund_detail.exit_load[0].Value == 0
                                        ? "Nil"
                                        : parseFloat(
                                            item.fund_detail.exit_load[0].Value
                                          ) *
                                            1 +
                                          "% "
                                      : "-"}
                                    {item.fund_detail.exit_load.length > 0 &&
                                    item.fund_detail.exit_load[0].Value * 1 !=
                                      0 ? (
                                      item.fund_detail.exit_load.length > 0 ? (
                                        <span
                                          className="Text"
                                          style={{ fontWeight: 100 }}
                                        >
                                          if redeemed within{" "}
                                          {item.fund_detail.exit_load[0]
                                            .HighBreakpoint *
                                            1 >
                                          0
                                            ? item.fund_detail.exit_load[0]
                                                .HighBreakpoint
                                            : item.fund_detail.exit_load[0]
                                                .LowBreakpoint}{" "}
                                          {
                                            item.fund_detail.exit_load[0]
                                              .BreakpointUnit
                                          }
                                        </span>
                                      ) : (
                                        <></>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="BottomLine"></div>
                          </div>
                        </div>
                        <div className="section" id="Compare" ref={CompareRef}>
                          <div className="Table_Compare ">
                            <div
                              className="Table_compare_Opt section-heading"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <span className="header_NM">
                                  Compare with Other Funds
                                </span>
                              </div>
                              <div style={{ float: "right", color: "gray" }}>
                                <button
                                  disabled={compareArray.length == 0}
                                  onClick={() => openComparePage()}
                                  className="fundComp"
                                >
                                  Compare
                                </button>
                              </div>
                            </div>
                            <table id="DMF_table" className="">
                              <tr>
                                <th
                                  style={{
                                    textAlign: "center",
                                    paddingLeft: "3rem",
                                  }}
                                >
                                  Funds Name
                                </th>
                                <th>1Y(%)</th>
                                <th>3Y(%)</th>
                                <th>5Y(%)</th>

                                <th>AUM (in Cr.)</th>
                              </tr>
                              {item.compare.map((v) => (
                                <tr>
                                  <td>
                                    <span className="fund-checkbx">
                                      <input
                                        checked={
                                          compareArray.findIndex(
                                            (x) =>
                                              x.scheme_slug == v.scheme_slug
                                          ) > -1
                                        }
                                        type="checkbox"
                                        name=""
                                        id=""
                                        value={v.scheme_slug}
                                        onChange={(e) => {
                                          handleChooseCompare(v);
                                        }}
                                      />
                                    </span>
                                    <span className="fund_amc">
                                      <a
                                        href={
                                          v.scheme_slug != ""
                                            ? process.env.PUBLIC_URL +
                                              "/direct-mutual-fund/MutualFund/" +
                                              v.scheme_slug
                                            : process.env.PUBLIC_URL +
                                              "/direct-mutual-fund/MutualFund/" +
                                              v.scheme_code
                                        }
                                        style={{
                                          textDecoration: "none",
                                          color: "#72b1d7",
                                        }}
                                      >
                                        {v.scheme_name}
                                      </a>
                                    </span>
                                  </td>
                                  <td>{(v.return_1yr * 1).toFixed(2)} %</td>
                                  <td>{(v.return_3yr * 1).toFixed(2)} %</td>
                                  <td>{(v.return_5yr * 1).toFixed(2)} %</td>

                                  <td>
                                    {/* {numDifferentiation(v.total.toFixed(2))} */}
                                    {v.total === null
                                      ? "-"
                                      : numDifferentiation(v.total.toFixed(2))}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                          <div className="Mobile_Compare_Opt">
                            <div className="Mobile_Table">
                              <div className="table-left">
                                <table>
                                  <tr>
                                    <th>Fund Name</th>
                                  </tr>
                                  {item.compare.map((v) => (
                                    <tr>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        <span>
                                          <input
                                            type="checkbox"
                                            name=""
                                            id=""
                                            style={{ marginRight: "10px" }}
                                            checked={
                                              compareArray.findIndex(
                                                (x) =>
                                                  x.scheme_code == v.scheme_code
                                              ) > -1
                                            }
                                            value={v.scheme_code}
                                            onChange={(e) => {
                                              handleChooseCompare(v);
                                            }}
                                          />
                                        </span>
                                        <span>{v.scheme_name}</span>
                                      </td>
                                    </tr>
                                  ))}
                                </table>
                              </div>
                              <div className="table-center">
                                <table>
                                  <tr className="tablr_Head">
                                    <th>1Y(%)</th>
                                    <th>3Y(%)</th>
                                    <th>5Y(%)</th>

                                    <th>AUM (in Cr.)</th>
                                  </tr>
                                  {item.compare.map((v) => (
                                    <tr>
                                      <td>{(v.return_1yr * 1).toFixed(2)} %</td>
                                      <td>{(v.return_3yr * 1).toFixed(2)} %</td>
                                      <td>{(v.return_5yr * 1).toFixed(2)} %</td>

                                      <td>
                                        {v.total === null
                                          ? "-"
                                          : numDifferentiation(
                                              v.total.toFixed(2)
                                            )}
                                        {/* {numDifferentiation(v.total.toFixed(2))} */}
                                      </td>
                                    </tr>
                                  ))}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="section"
                          id="AssetAllo"
                          ref={AssetAlloRef}
                        >
                          <h4 className="section-heading">Allocation</h4>
                          <div className="BottomLine"></div>
                          <Allocation
                            dataAssets={[
                              {
                                title: "Large cap",
                                value: item.asset_allocation.large_cap,
                                color: "#008FFB",
                              },
                              {
                                title: "Mid cap",
                                value: item.asset_allocation.mid_cap,
                                color: "#00E396",
                              },
                              {
                                title: "Small cap",
                                value: item.asset_allocation.small_cap,
                                color: "#FEB019",
                              },
                              {
                                title: "Others",
                                value: item.asset_allocation.other_capital,
                                color: "#8F00FF",
                              },
                            ]}
                            dataDebts={[
                              {
                                title: "A",
                                value: item.asset_allocation.credit_qual_a,
                                color: "#008FFB",
                              },
                              {
                                title: "AA",
                                value: item.asset_allocation.credit_qual_aa,
                                color: "#00E396",
                              },
                              {
                                title: "AAA",
                                value: item.asset_allocation.credit_qual_aaa,
                                color: "#FEB019",
                              },
                              {
                                title: "B",
                                value: item.asset_allocation.credit_qual_b,
                                color: "#8F00FF",
                              },
                              {
                                title: "BB",
                                value: item.asset_allocation.credit_qual_bb,
                                color: "#009933",
                              },
                              {
                                title: "BBB",
                                value: item.asset_allocation.credit_qual_bbb,
                                color: "#993333",
                              },
                            ]}
                            sector_alloc={item.sector_allocation}
                            asset_allocation={item.asset_allocation}
                          />
                        </div>
                        <div
                          className="Top_Holdings section"
                          id="TopHold"
                          ref={TopHoldRef}
                        >
                          <div className="Holdings_table">
                            <h4>Holdings</h4>
                            <table className="Table_Hold">
                              <tr>
                                <th>Name</th>
                                <th>Sector</th>
                                <th>Asset class</th>
                                <th>Assets</th>
                              </tr>
                              {item.top_holdings
                                .filter((v, i) =>
                                  show5funds ? (i < 5 ? true : false) : true
                                )
                                .map((val) => (
                                  <tr>
                                    <td>
                                      <div className="icon-text-bxline">
                                        <img
                                          src={require("../../Assets/Images/02_All_stocks_Blue.png")}
                                          alt="Aditya Biral Sun Life Liquid Fund"
                                        />
                                        <p className="Nm">{val.comp_name}</p>
                                      </div>
                                    </td>
                                    <td>
                                      {val.sector_name !== ""
                                        ? val.sector_name
                                        : "Others"}
                                    </td>
                                    <td>{val.fintoo_category}</td>
                                    <td>
                                      {Number(val.periodic_hold).toFixed(1)}%
                                    </td>
                                  </tr>
                                ))}
                            </table>
                            {item.top_holdings.length > 5 &&
                              show5funds === true && (
                                <p
                                  onClick={() => setShow5Funds(false)}
                                  className="Checkall"
                                  style={{
                                    color: "#72b1d7",
                                    // fontSize: "17px",
                                    fontWeight: "900",
                                  }}
                                >
                                  {/* View All <FaAngleDown /> */}
                                  View all
                                </p>
                              )}
                            {item.top_holdings.length > 5 &&
                              show5funds === false && (
                                <p
                                  onClick={() => {
                                    setShow5Funds(true);
                                    scrollTo(TopHoldRef.current);
                                  }}
                                  className="Checkall"
                                  style={{
                                    color: "#72b1d7",
                                    // fontSize: "17px",
                                    fontWeight: "900",
                                  }}
                                >
                                  {/* Show Less <FaAngleUp /> */}
                                  View less
                                </p>
                              )}
                          </div>
                          <div className="Mobile_Holdings">
                            {item.top_holdings
                              .filter((v, i) =>
                                show5funds ? (i < 5 ? true : false) : true
                              )
                              .map((val) => (
                                <div className="Top_Holding_List">
                                  <div>{val.comp_name}</div>
                                  <div className="RangeData">
                                    <div className="range-outer">
                                      <div
                                        className="range-inner"
                                        style={{
                                          width: val.periodic_hold + "%",
                                        }}
                                      ></div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          float: "right",
                                          color: "gray",
                                        }}
                                      >
                                        {val.periodic_hold}%
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <br />
                            {item.top_holdings.length > 5 &&
                              show5funds === true && (
                                <p
                                  onClick={() => setShow5Funds(false)}
                                  className="check"
                                  style={{
                                    color: "gray",
                                    fontSize: "17px",
                                    fontWeight: "900",
                                  }}
                                >
                                  View All <FaAngleDown />
                                </p>
                              )}
                            {item.top_holdings.length > 5 &&
                              show5funds === false && (
                                <p
                                  onClick={() => setShow5Funds(true)}
                                  className="check"
                                  style={{
                                    color: "gray",
                                    fontSize: "17px",
                                    fontWeight: "900",
                                  }}
                                >
                                  Show Less <FaAngleUp />
                                </p>
                              )}
                          </div>
                        </div>
                        <div
                          className="section"
                          id="RiskMeasure"
                          ref={RiskMeasureRef}
                        >
                          <div className="Risk_mesures">
                            <table className="Table_Hold">
                              <tr>
                                <th>Risk Measures (%)</th>
                                <th>Mean</th>
                                <th>Beta</th>
                                <th>Alpha</th>
                              </tr>
                              <tr>
                                <td>{item.risk_measure.scheme_name}</td>
                                <td>
                                  {item.risk_measure.mean_3yr !== null
                                    ? (1 * item.risk_measure.mean_3yr).toFixed(
                                        2
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {item.risk_measure.beta_3yr !== null
                                    ? (1 * item.risk_measure.beta_3yr).toFixed(
                                        2
                                      )
                                    : "-"}
                                </td>
                                <td>
                                  {item.risk_measure.alpha_3yr !== null
                                    ? (1 * item.risk_measure.alpha_3yr).toFixed(
                                        2
                                      )
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>Rank within category</td>
                                <td>{item.rank_list.avMean_rank}</td>
                                <td>{item.rank_list.avBeta_rank}</td>
                                <td>{item.rank_list.avAlpha_rank}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="section" id="Others" ref={OthersRef}>
                          <div className="OthersPanel">
                            <h4 className="section-heading">Others</h4>
                            <hr />
                            <div className="other-detail-main-box">
                              <div className="d-flex">
                                <span>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/static/media/companyicons/${item.Overview.amc_code}.png`}
                                    alt="Canaera Robeco Mutual Fund"
                                  />
                                </span>
                                <span className="Nm">
                                  {item.Overview.scheme_name}
                                </span>
                              </div>

                              <div className="Other_Detail">
                                <div
                                  className="Other_First"
                                  style={{ display: "grid" }}
                                >
                                  <span>Date of Incorporation</span>
                                  <span>
                                    {moment(item.Overview.launch_date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </div>
                                <span className="Vertical_Line"></span>
                                <div
                                  className="Other_First"
                                  style={{ display: "grid" }}
                                >
                                  <span>Total AUM</span>
                                  <span>
                                    {numDifferentiation(
                                      item.Overview.aum_total
                                    )}
                                  </span>
                                </div>
                              </div>
                              <hr />
                              <div className="RegName">
                                <p
                                  style={{ color: "gray", marginBottom: 0 }}
                                  className="fund-bx-title"
                                >
                                  Registrar Name
                                </p>
                                <span className="UserNM">
                                  {item.others.rt_name}
                                </span>
                              </div>
                              <div className="FundMangerName">
                                <p
                                  style={{ color: "gray", marginBottom: 0 }}
                                  className="fund-bx-title"
                                >
                                  Fund Manager{" "}
                                </p>
                                <div style={{ display: "flex" }}>
                                  <span className="UserNM">
                                    {item.others.managers}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bottom-spacer" />
                    </div>
                  </div>
                </div>

                <div className="RightSection col-12 col-md-4">
                  <div className="RightSide SIP_Lumpsum ps-lg-2" id="fin-calc">
                    {productDetail.length > 0 && (
                      <TabData
                        fundData={productDetail[0]}
                        onAmountChange={(v) => onAmountChange(v)}
                      />
                    )}
                  </div>
                  <div className="RightSide ps-lg-2">
                    <div id="Calc_Amt_Range">
                      <p>Calculate amount (Rs)</p>
                      <div className="return">
                        <div className="Amtreturn">
                          <div> Expected Return</div>
                          <div style={{ color: "#000" }}>
                            {calculatorData.expectedReturns}%
                          </div>
                        </div>
                        <InputSlider
                          min={0}
                          max={100}
                          onChange={(v) => setCalculatorData(prev=> ({...prev, expectedReturns: v}))}
                          value={calculatorData?.expectedReturns}
                        />
                      </div>
                      <div className="invest">
                        <div className="Amtreturn">
                          <div> Expected Investment Duration</div>
                          <div style={{ color: "#000" }}>
                            {calculatorData.expectedDuration} Yrs
                          </div>
                        </div>
                        <InputSlider
                          min={0}
                          max={100}
                          onChange={(v) => setCalculatorData(prev=> ({...prev, expectedDuration: v}))}
                          value={calculatorData?.expectedDuration}
                        />
                      </div>
                    </div>
                    <div className="amnt">
                      <div className="Box_Value">
                        <div className="IM">
                          <span className="TextNam">Invested Amount</span>
                          <div style={{ display: "grid" }}>
                            <span>
                              <span
                                style={{
                                  color: "#000",
                                  fontSize: "18px",
                                  fontWeight: "600",
                                }}
                              >
                                {indianRupeeFormat(formatPrice(calculatorData.investedAmount))}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="Est_Re">
                          <span className="TextNam">Est. Returns</span>
                          <span
                            style={{
                              color: "#000",
                              fontSize: "18px",
                              fontWeight: "600",
                              wordBreak: "break-all",
                              overflow: "hidden",
                              width: "100%",
                              display: "block",
                            }}
                          >
                            {formatPrice(calculatorData?.estReturns??0)}
                          </span>
                        </div>
                      </div>
                      <div className="Total_Val">
                        <span>Total Value</span>
                        <span className="Total_amnt">
                          <span className="Value">
                            {formatPrice(Math.round(calculatorData?.expectedResult))}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="riskGraph">
                      <h5>Risk Meter</h5>
                      <div className="risk_Meter ">
                        {productDetail.length && (
                          <h5>{productDetail[0]["Overview"]["risk"]}</h5>
                        )}
                        <div>
                          {/* <p>{riskArray.filter((v)=> v.type === productDetail[0]['Overview']['risk'])[0]['value']}</p> */}
                          {productDetail.length && (
                            <RiskMeter
                              key={"riskmeter"}
                              value={
                                riskArray.filter(
                                  (v) =>
                                    v.type ===
                                    productDetail[0]["Overview"]["risk"]
                                )[0]["value"]
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Row>
        </Container>

        <Modal
          className="white-modal frm-buy-mobile"
          show={showBuyModal}
          onHide={() => setShowBuyModal(false)}
        >
          <Modal.Header closeButton className="py-3">
            <div className="modal-title">{mobilePurchase.toUpperCase()}</div>
          </Modal.Header>
          <Modal.Body>
            <p>&nbsp;</p>
            <h3>
              {productDetail.length > 0
                ? productDetail[0]["Overview"]["scheme_name"]
                : ""}
            </h3>
            <TabData
              type={mobilePurchase}
              fundData={productDetail[0]}
              onAmountChange={(v) => onAmountChange(v)}
            />
          </Modal.Body>
        </Modal>

        <div className="mobile-purchase-btns d-flex d-md-none">
          <div>
            <button
              onClick={() => {
                setMobilePurchase("sip");
                setShowBuyModal(true);
              }}
            >
              SIP
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                setMobilePurchase("lumpsum");
                setShowBuyModal(true);
              }}
            >
              Lumpsum
            </button>
          </div>
        </div>
      </div>
    </GuestLayout>
  );
};

export default MutualFund;

import React, { useRef, useState, useEffect } from "react";
import PortfolioLayout from "../../../components/Layout/Portfolio";
import pmc from "../../../components/Layout/Portfolio/portfolio.module.css";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import FintooBackButton from "../../../components/HTML/FintooBackButton";
import moment from "moment";

import style from "./style.module.css";

import * as constClass from "../../../constants";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  getUserId,
  loginRedirectGuest,
  apiCall,
  restApiCall,
  getRestApiHeaders,
  getParentUserId,
  getItemLocal,
  setItemLocal,
  setMemberId,
  fetchEncryptData,
  fetchData,
} from "../../../common_utilities";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import IncompleteRegistration from "../../../components/IncompleteRegistration";
import FintooLoader from "../../../components/FintooLoader";
import OTPInput from "otp-input-react";

function LInkyourholdings(props) {
  const [show, setShow] = useState(false);
  const [allMembers, setAllMembers] = useState([]);

  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);
  const [modalType, setModalType] = useState(-1);
  // const [accToken, setAccToken] = useState("");
  // const [restHeaders, setRestHeaders] = useState({});
  const [selectedMember, setSelectedMember] = useState({});
  // const [isProfileIncomplete, setIsProfileIncomplete] = useState(false);
  const [otpInput, setOtpInput] = useState("");
  const [foundMobile, setFoundMobile] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const interval = useRef(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [errormsg, setErrorMessage] = useState({});
  const [sendDisabled, setSendDisabled] = useState(true);

  const timeNewObj = useRef();
  const timeNewValue = useRef(120);
  const stopSmallCase = useRef(false);
  const retryStatusCheckRef = useRef(0);

  const msg = errormsg;

  const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
  };
  const maxLimit = 300;

  useEffect(() => {
    if (getUserId() == null) {
      loginRedirectGuest();
    }
    if (getItemLocal("family")) {
      navigate(
        process.env.PUBLIC_URL + "/direct-mutual-fund/portfolio/dashboard"
      );
    }
    onLoadInit();
    document.body.classList.add("link-your-holding");
    return () => {
      document.body.classList.remove("link-your-holding");
      clearInterval(interval.current);
      clearInterval(timeNewObj.current);
      timeNewValue.current = 120;
    };
  }, []);

  const onLoadInit = async () => {
    try {
      // var accTok = await getRestApiHeaders();
      // if (accTok) {
      //   setAccToken(accTok.gatewayauthtoken);
      //   setRestHeaders(accTok);
      // }
      fetchMembers();
    } catch (e) {
      console.log(e);
    }
  };

  console.log("nil40-", selectedMember);

  const checkHoldingStatus = async () => {
    try {
      console.log("nil4", selectedMember);
      const payload = {
        method: "post",
        url: constClass.RMF_GET_SC_CHECK_STATUS,
        data: {
          pan: selectedMember.pan,
          is_direct: constClass.IS_DIRECT,
        },
      };
      const res = await fetchData(payload);
      // console.log('nil67', res);
      if (res.error_code == 100) {
        console.log("nil67", res.data);
        if (Array.isArray(res.data) && res.data.length > 0) {
          let _a = res.data[0];
          if (
            moment().startOf("day").valueOf() ==
            moment(_a.Updated_Datetime).startOf("day").valueOf()
          ) {
            switch (res.data[0].status.toLowerCase()) {
              case "success":
                setModalType(3);
                break;
              case "pending":
                if (retryStatusCheckRef.current < 15) {
                  retryStatusCheckRef.current = retryStatusCheckRef.current + 1;
                  setTimeout(() => checkHoldingStatus(), 20000);
                }
                setModalType(2);
                break;
              default:
                setModalType(0);
            }
          } else {
            setModalType(0);
          }
        } else {
          setModalType(0);
        }
      } else {
        setModalType(0);
      }
    } catch {}
  };

  const fetchMembers = async () => {
    try {
      const resp = await apiCall(constClass.GET_MEMBER_LIST, {
        user_id: getParentUserId(),
        is_direct: constClass.IS_DIRECT,
        ecas: "1",
      });
      const all = resp.data.map((v) => ({
        name: v.NAME ? v.NAME : v.fdmf_email,
        id: v.id,
        fp_log_id: v.fp_log_id,
        parent_user_id: v.parent_user_id,
        pan: v.pan,
        mobile: v.mobile,
        email: v.fdmf_email,
        fp_user_details_id: v.fp_user_details_id,
        label: v.NAME ? v.NAME : v.fdmf_email,
        value: v.id,
      }));
      setAllMembers([...all]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleChange();
  }, [allMembers]);

  useEffect(() => {
    if (Object.keys(selectedMember).length > 0) {
      checkHoldingStatus();
    }
  }, [selectedMember]);

  const findMobileErrors = () => {
    const newErrors = {};
    let regex = /^[789]\d{9}$/;
    if (Boolean(selectedMember?.mobile) && selectedMember.mobile.length !== 10)
      newErrors.userMobile = "Please enter valid mobile number!";
    else if (
      Boolean(selectedMember?.mobile) &&
      !regex.test(selectedMember.mobile)
    )
      newErrors.userMobile = "Please enter valid mobile number!";
    return newErrors;
  };

  const findOtpErrors = () => {
    const newErrors = {};
    if (!otpInput || otpInput === "")
      newErrors.otpInput = "Please enter valid otp!";
    else if (otpInput.length !== 6)
      newErrors.otpInput = "Please enter valid otp!";
    return newErrors;
  };

  const sendOTP = async () => {
    if(selectedMember?.mobile){

      try {
        const mobileErrors = findMobileErrors();
        if (Object.keys(mobileErrors).length > 0) {
          setErrors(mobileErrors);
          return;
        }

        let checkPan = await checkIfPanExists();
        if (checkPan != true) {
          dispatch({
            type: "RENDER_TOAST",
            payload: {
              message: checkPan,
              type: "error",
            },
          });
          return;
        }

        let jwtTok = await getJwtToken();
        if (jwtTok.error_code == "100") {
          let trxnIdData = await getTransactionId(jwtTok.data.token);
          if (trxnIdData.error_code == "100") {
            let trxnId = trxnIdData.data.data.data.transactionId;
            let sendOTP = await sendSmallcaseOTP(trxnId);
            if (sendOTP.error_code == "100") {
              clearInterval(timeNewObj.current);
              timeNewValue.current = 120;
              setItemLocal("trxnId", trxnId);
              // setShow(true);
              setModalType(1);
              setDefaultTimer();
              setOtpInput("");
              startTimer();
              setErrors({});
              return;
            }
          }
        }

        dispatch({
          type: "RENDER_TOAST",
          payload: {
            message: msg,
            type: "error",
          },
        });
      } catch (e) {
        console.log(e);
      }
    }else{

      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Please enter Mobile number",
          type: "error",
        },
      });
  }
};

  const handleOtpChange = (e) => {
    setOtpInput(e.target.value);
  };

  const checkIfPanExists = async () => {
    try {
      var reqData = {
        method: "post",
        url: constClass.DMF_CHECKIFPANEXISTS_API_URL,
        data: {
          pan: selectedMember?.pan,
          fp_user_id: selectedMember?.id,
        },
      };
      let checkPan = await fetchData(reqData);
      if (checkPan.error_code == "100") return true;
      else if (checkPan.error_code == "101") return message;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const getJwtToken = async () => {
    try {
      var reqData = {
        method: "post",
        url: constClass.GET_JWTTOKEN_API_URL,
        data: {
          user_id: selectedMember.id,
          //fp_log_id: selectedMember.fp_log_id,
          is_chat_bot: 1,
        },
      };

      let jwtTok = await fetchData(reqData);
      if (jwtTok.error_code == "100") return jwtTok;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const getTransactionId = async (jwtToken) => {
    try {
      let trxnId = await fetchData({
        method: "post",
        url: constClass.GETTRANSACTION_API_URL,
        data: {
          token: jwtToken,
        },
        // headers: { ...restHeaders },
      });
      if (trxnId.error_code == "100") return trxnId;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const sendSmallcaseOTP = async (trxnId) => {
    try {
      let payload = {
        method: "post",
        url: constClass.SEND_SC_OTP_API_URL,
        data: {
          transactionId: trxnId,
          pan: selectedMember?.pan,
          phone: selectedMember?.mobile,
        },
        // headers: { ...restHeaders },
      };

      let sendOTP = await fetchData(payload);
      setErrorMessage(sendOTP.message)
      if (sendOTP.error_code == "100") return sendOTP;
      return "Something went wrong!";
    } catch (e) {
      console.error(e);
    }
  };

  const verifySmallcaseOTP = async () => {
    try {
      const otpErrors = findOtpErrors();
      if (Object.keys(otpErrors).length > 0) {
        setErrors(otpErrors);
        return;
      }

      let trxnId = getItemLocal("trxnId");
      const payload = {
        method: "post",
        data: {
          transactionId: trxnId,
          pan: selectedMember?.pan,
          phone: selectedMember?.mobile,
          is_chat_bot: 1,
          otp: otpInput,
          is_direct: constClass.IS_DIRECT,
        },
        url: constClass.VERIFY_SC_OTP_API_URL,
      };

      let verifyOTP = await fetchData(payload);

      let errMsg = "";

      if (verifyOTP.error_code == "100") {
        setModalType(2);
        getSmallCaseData();
        localStorage.setItem("reloadPortfolio", 1);
        return;
      } else if (verifyOTP.error_code) {
        let errResp = JSON.parse(verifyOTP.data.data.data);
        errMsg = errResp.errors[0]?.message;
      }

      if (errMsg.includes("Entered OTP appears to be incorrect")) {
        setErrors({ otpInput: errMsg });
        return;
      }
      setModalType(0);
      setDefaultTimer();
      setOtpInput("");
      setErrors({});
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: errMsg ? errMsg : "Someting went wrong!",
          type: "error",
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getSmallCaseData = async () => {
    dispatch({ type: "LINK_HOLDING_EVENT", payload: { ...selectedMember } });
  };

  const handleChange = async (e) => {
    try {
      let isFoundMobile = false;
      if (Boolean(e) == false) {
        let member = allMembers.filter((v) => v.id == getUserId());
        setSelectedMember({ ...member[0] });
        if(member[0]?.mobile) {
          isFoundMobile = true;
        }
        setSendDisabled(false);
      } else {
        setSelectedMember(e);
        if(e?.mobile) {
          isFoundMobile = true;
        }
        setErrors({});
        setSendDisabled(false);
      }
      setFoundMobile(isFoundMobile);
    } catch (e) {}
  };

  useEffect(() => {
    setDefaultTimer();
  }, []);

  const startTimer = () => {
    timeNewObj.current = setInterval(function () {
      if (timeNewValue.current <= 0) {
        clearInterval(timeNewObj.current);
      } else {
        timeNewValue.current = timeNewValue.current - 1;
        setCount(timeNewValue.current);
      }
    }, 1000);
  };

  const setDefaultTimer = () => {
    timer.current.counter = timer.current.default;
  };

  const handleMobileChange = (e) => {
    const newMobile = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
    setSelectedMember({ ...selectedMember, mobile: newMobile });
    if (e.target.value.length == 10) {
      findMobileErrors();
    }
  };

  useEffect(() => {
    console.log("sdds", show, modalType);
    if (show && modalType) {
      document.getElementById("root").classList.add("blur-bg");
    } else {
      document.getElementById("root").classList.remove("blur-bg");
    }
  }, [show, modalType]);

  return (
    <PortfolioLayout>
      {/* <FintooLoader isLoading={isLoading} /> */}
      <IncompleteRegistration
        // open={isProfileIncomplete}
        onCloseModal={() => {
          // setIsProfileIncomplete(false);
          setSelectedMember({});
        }}
        handleSubmit={() => {
          setMemberId(selectedMember.id);
          {
            status === "N"
              ? navigate(
                  process.env.PUBLIC_URL + "/direct-mutual-fund/Profile?s=Birth"
                )
              : navigate(
                  process.env.PUBLIC_URL + "/direct-mutual-fund/profile"
                );
          }
        }}
      />

      <div className={`cotaininer ${pmc.linkholdings}`}>
        <div className="row">
          <div className="col-4 m-auto">
            <div className="holdings-form-box p-3">
              {modalType == 0 && (
                <>
                  <div className={`${pmc.headerbox}`}>
                    <div className={`text-center ${pmc.Heading} d-flex pb-3`}>
                      <Link
                        to={
                          process.env.PUBLIC_URL +
                          "/direct-mutual-fund/portfolio/dashboard"
                        }
                      >
                        <img
                          style={{
                            transform: "rotate(180deg)",
                          }}
                          width={20}
                          height={20}
                          src={
                            process.env.PUBLIC_URL +
                            "/static/media/icons/chevron.png"
                          }
                        />
                      </Link>
                      <p className="text-center mb-0">
                        Link Your Mutual Fund Holdings
                      </p>
                    </div>
                  </div>
                  <div className={`${pmc.formSection} pt-3`}>
                    <div className={`text-center ${pmc.Text}`}>
                      Please select the respective member from the dropdown menu
                      to fetch holdings
                    </div>
                    <div className={`${pmc.FormsFillup}`}>
                      {getItemLocal("family") && (
                        <div className="mt-md-5">
                          <span className={`${pmc.FormlableName}`}>
                            Member *
                          </span>
                          <Select
                            style={{
                              width: "100% !Important",
                            }}
                            classNamePrefix="sortSelect"
                            isSearchable={false}
                            options={allMembers}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={allMembers.filter(
                              (v) => v.id == selectedUser
                            )}
                          />
                        </div>
                      )}

                      <div className="mt-md-5">
                        <div className="">
                          <span className={`${pmc.FormlableName}`}>PAN *</span>
                          <br />
                          <input
                            placeholder="Enter Your PAN"
                            className={`${pmc.inputs} w-100`}
                            type="text"
                            value={selectedMember.pan}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="mt-md-5">
                        <div className="">
                          <span className={`${pmc.FormlableName}`}>
                            Mobile Number *
                          </span>
                          <br />
                          <input
                            maxLength={10}
                            placeholder="Enter Mobile Number"
                            className={`${pmc.inputs} w-100`}
                            type="text"
                            value={selectedMember?.mobile}
                            onChange={(e) => handleMobileChange(e)}
                            disabled={foundMobile ? "disabled" : ""}
                          />
                          {errors.userMobile && (
                            <p className="error">{errors.userMobile}</p>
                          )}
                        </div>
                      </div>
                      <div className={`${pmc.OtpBtn}`}>
                        {
                        <button
                          disabled={sendDisabled}
                          onClick={() => sendOTP()}
                        >
                          Send OTP
                        </button>
                        }
                      </div>
                    </div>
                  </div>
                </>
              )}
              {modalType == 1 && (
                <>
                  <div className="d-flex justify-center ">
                    <div>
                      {" "}
                      <FintooBackButton
                        onClick={() => {
                          setModalType(0);
                        }}
                        onChange={(e) => handleOtpChange(e)}
                      />
                    </div>
                    <div
                      className="DeleteBank text-center pb-3 w-100"
                      style={{
                        borderBottom: "1px solid #eeee",
                      }}
                    >
                      <h3 className="mb-0 pb-0">Please Enter OTP</h3>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="">
                        <div>
                          <div className="modal-whitepopup-box-item  border-top-0 text-center">
                            <p>
                              We have sent you and OTP on your mobile number{" "}
                              <b>
                                +91{" "}
                                {selectedMember?.mobile
                                  .split("")
                                  .map((v, i) => (i > 2 && i < 8 ? "*" : v))
                                  .join("")}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div
                          className={`d-flex justify-center align-items-center  ${style.enterbox}`}
                        >
                          <div className="m-auto">
                            <OTPInput
                              value={otpInput}
                              onChange={setOtpInput}
                              autoFocus
                              className="link-holdings-otp w-100"
                              style={{
                                border: "none",
                              }}
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                            />
                            {errors.otpInput && (
                              <p className="otp-error">{errors.otpInput}</p>
                            )}
                          </div>
                        </div>
                        <div className="text-center grey-color">
                          {count == 0 && (
                            <p
                              className="pointer blue-color"
                              onClick={() => {
                                sendOTP();
                              }}
                            >
                              Resend OTP
                            </p>
                          )}
                          {count > 0 && (
                            <p>
                              Expire in &nbsp;
                              <strong>
                                {moment()
                                  .startOf("day")
                                  .seconds(count)
                                  .format("mm:ss")}
                              </strong>
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        className={` ${pmc.OTpConfirm}`}
                        onClick={() => {
                          verifySmallcaseOTP();
                        }}
                      >
                        Submit
                      </div>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </>
              )}
              {modalType == 2 && (
                <>
                  <div className={style["modal-cntn"] + " p-4"}>
                    <div>
                      <p className="pt-3">
                        <strong>
                          Your external mutual Fund portfolio sync is in
                          progress
                        </strong>
                      </p>
                      <p>
                        This may take 10 to 15 minutes. We will notify you once
                        your external portfolio is synced.
                      </p>
                    </div>
                    <div>
                      <button
                        className={style["long-btn"]}
                        onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL +
                              "/direct-mutual-fund/portfolio/dashboard"
                          );
                        }}
                      >
                        DONE
                      </button>
                    </div>
                  </div>
                </>
              )}
              {modalType == 3 && (
                <>
                  <div className={style["modal-cntn"] + " p-4"}>
                    <div>
                      <p className="pt-3">
                        <strong>
                          Your external mutual Fund portfolio synced today.
                        </strong>
                      </p>
                      <p>
                        You can only link your holdings once every 24 hours. Try
                        again later.
                      </p>
                    </div>
                    <div>
                      <button
                        className={style["long-btn"]}
                        onClick={() => {
                          navigate(
                            process.env.PUBLIC_URL +
                              "/direct-mutual-fund/portfolio/dashboard"
                          );
                        }}
                      >
                        DONE
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PortfolioLayout>
  );
}

export default LInkyourholdings;

import { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import Profile_1 from "../../../Assets/06_banking_app.svg";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Fatca/style.css";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import FintooButton from "../../../HTML/FintooButton";
import axios from "axios";
import {
  DMF_UPDATEBANK_API_URL,
  DMF_DELETEBANK_API_URL,
  DMF_GETBANK_BSECODE_API_URL
} from "../../../../constants";
import commonEncode from "../../../../commonEncode";
import * as constClass from "../../../../constants";
import {
  apiCall,
  CheckSession,
  getMinorUserId,
  memberId,
} from "../../../../common_utilities";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function ConfirmBank(props) {
  const [validated, setValidated] = useState(false);
  const [bankDetails, setBankDetails] = useState({});
  const [bankBseCode, getBankBseCode] = useState({});
  const [bank, setBank] = useState({});
  const dispatch = useDispatch();
  const showBack = useSelector((state) => state.isBackVisible);
  const user_id = props.value == "minor" ? getMinorUserId() : memberId();
  const [responsebank, setresponse] = useState("");
  var local_id = "";
  const [bnkacc, setacc] = useState("");
  const [bnkadd, setadd] = useState("");
  const [bnknme, setnme] = useState("");
  const [bnkifsc, setifsc] = useState("");
  const [bnkcode, setcode] = useState("");
  const [bsecode, setBseCode] = useState("");


  var retrievedData = localStorage.getItem("Bank_DETAILS");
  var retrievedObject = JSON.parse(retrievedData);

  useEffect(() => {
   if (localStorage.getItem("Bank_DETAILS") == null){
    getuserbankdetails();
   }
  }, []);

  useEffect(() => {
    if(localStorage.getItem("Bank_DETAILS") !== null){
      setBank(retrievedObject)
      setGetBankBseCode();
    }
    if (localStorage.getItem("YmFua19pZA==")) {
      bank_id = localStorage.getItem("YmFua19pZA==");
      local_id = commonEncode.decrypt(bank_id);
    }
    if (user_id != null && local_id != null) {
      
    }
  }, []);

  const getuserbankdetails = async () => {
    var data = { user_id: user_id };

    var data = commonEncode.encrypt(JSON.stringify(data));
    
    var config = {
      method: "post",
      url: constClass.DMF_GETUSERBANKS_API_URL,
      data: data,
    };

    var res = await axios(config);

    var response = commonEncode.decrypt(res.data);
    
    var details = JSON.parse(response)["data"][0];
    var bank_code = JSON.parse(response)["data"][0].bank_bse_code;
    var bankid = JSON.parse(response)["data"][0].bank_id;
    localStorage.setItem(
      "YmFua19pZA==",
      commonEncode.encrypt(bankid.toString())
    );

    setBank(details);
    setBseCode(bank_code)
  };

  

  useEffect(() => {
    // CheckSession();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    
  }, []);

  
  let navDynamicNext = "";
  let navDynamicPrev = "";
  
  let bank_id = "";
  if(localStorage.getItem("bmF2RHluYW1pY05leHQ=")){
    navDynamicNext = commonEncode.decrypt(
      localStorage.getItem("bmF2RHluYW1pY05leHQ=")
    );
  }
  if(localStorage.getItem("bmF2RHluYW1pY1ByZXY=")){
    navDynamicPrev = commonEncode.decrypt(
      localStorage.getItem("bmF2RHluYW1pY1ByZXY=")
    );
  }

  if(localStorage.getItem("YmFua19pZA==")){
    bank_id = localStorage.getItem("YmFua19pZA==");
  }

  const deleteBank = async () => {
    let data = {};
    var bank_id = localStorage.getItem("YmFua19pZA==");
    local_id = commonEncode.decrypt(bank_id);

    data["bank_user_id"] = user_id;
    data["bank_id"] = local_id;

    let post_data = commonEncode.encrypt(JSON.stringify(data));
    var res = await axios({
      url: DMF_DELETEBANK_API_URL,
      method: "POST",
      data: post_data,
    });

    let response = commonEncode.decrypt(res.data);
    let responseobj = JSON.parse(response);
    
    let error_code = responseobj.error_code;
    if (error_code == "100") {
      
      
      props.onPrevious("Bank");
    }
  };

  const updateBank = async () => {
    clientRegistration();
    dispatch({
      type: "RENDER_TOAST",
      payload: { message: "Bank added successfully.", type: "success" },
      autoClose: 3000,
    });
    setTimeout(() => {
      props.onNext();
    }, 4000);
    localStorage.removeItem("bankDetails");
    localStorage.removeItem("sendData");
    
    localStorage.removeItem("accountNumber");
    localStorage.removeItem("ifscCode");
    localStorage.removeItem("bankType");
    localStorage.removeItem("res_type");
    localStorage.removeItem("navDynamicNext");
    localStorage.removeItem("navDynamicPrev");
  };

  const setGetBankBseCode = async () => {

    let retrievedObject = JSON.parse(localStorage.getItem("Bank_DETAILS"));
    var data = { bank_code: retrievedObject.bank_code };


    try {
      var config = {
        method: "post",
        url: DMF_GETBANK_BSECODE_API_URL,
        data: data,
      };
      var res = await axios(config);
      var response = res.data;

      getBankBseCode(response);
    } catch (e) {
      e.errorAlert();
    }
  };

  useEffect(() => {}, [bankDetails]);

  const clientRegistration = async () => {
    let url = constClass.DMF_CLIENT_REGISTRATION_API_URL;
    let data_sent = JSON.stringify({
      user_id: user_id,
      is_direct: constClass.IS_DIRECT,
    });

    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    if (error_code == "100") {
      FATCAUpload();
    } else {
      setTimeout(() => {
        props.onNext();
      }, 1000);
    }
  };

  const FATCAUpload = async () => {
    let url = constClass.DMF_FATCA_UPLOAD_API_URL;
    let data_sent = JSON.stringify({
      user_id: user_id,
      is_direct: constClass.IS_DIRECT,
    });
    var config = {
      method: "post",
      url: url,
      data: commonEncode.encrypt(data_sent),
    };
    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    let response_obj = JSON.parse(response);
    let error_code = response_obj["error_code"];
    if (error_code == "100") {
      toast.success("User details updated successfully.", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000,
      });
      setTimeout(() => {
        props.onNext();
      }, 1000);
    } else {
      toast.success("User details updated successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 2000,
      });
      setTimeout(() => {
        props.onNext();
      }, 1000);
    }
  };

  const handleSubmit = (event) => {
    if(localStorage.getItem("YmFua19pZA==")){
      var bank_id = commonEncode.decrypt(localStorage.getItem("YmFua19pZA=="))
      if (bank_id != null) {
        updateBankDetails();
      }  
    }else{
      Addbank();
    } 
      
  };

  const Addbank = async () => {
    var urladddata = {
      bank_user_id: user_id,
      bank_acc_no: retrievedObject.bank_acc_no,
      bank_type: retrievedObject.bank_type,
      bank_ifsc_code: retrievedObject.bank_ifsc_code,
      bank_name: retrievedObject.bank_name,
      is_primary: "0",
      bank_branch: retrievedObject.bank_branch,
      bank_code: retrievedObject.bank_code,
      bank_city: retrievedObject.bank_city,
      bank_state: retrievedObject.bank_state,
      bank_address: retrievedObject.bank_address,
      bank_country: "94",
      micr_code: retrievedObject.micr_code,
      single_survivor: props.value === "minor" ? "joint" : "single",
    };
    var data = commonEncode.encrypt(JSON.stringify(urladddata));
    var config = {
      method: "post",
      url: constClass.DMF_ADDBANK_API_URL,
      data: data,
    };

    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);

    var response_obj = JSON.parse(response);

    let bank_id = response_obj.data;

    const req = commonEncode.encrypt(JSON.stringify(bank_id));
    localStorage.setItem("YmFua19pZA==", req);

    let error_code = response_obj.error_code;
    if (error_code == "102") {
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: response_obj.message,
          type: "error",
          autoClose: 3500,
        },
      });
    } else if (error_code == "100") {
      updateBank();
      dispatch({
        type: "RENDER_TOAST",
        payload: {
          message: "Bank Account Saved!",
          type: "success",
          autoClose: 3000,
        },
      });
    }
  };

  const MyFunction = async () => {
    // var penyresponse = await penny();
    console.log("bankingggg",bank,retrievedObject)
    if(localStorage.getItem("YmFua19pZA==")){
      bank_id = commonEncode.decrypt(localStorage.getItem("YmFua19pZA=="));
    }
    // var bank_id = commonEncode.decrypt(localStorage.getItem("YmFua19pZA=="));
    if (local_id != "") {
      var bank_id = local_id;
    }
      var bankreq = {
        bank_id: bank_id,
        bank_user_id: user_id,
        bank_acc_no: retrievedObject?.bank_acc_no || bank.bank_acc_no,
        bank_type: retrievedObject?.bank_type || bank.bank_type,
        bank_ifsc_code: retrievedObject?.bank_ifsc_code || bank.bank_ifsc_code,
        bank_name: retrievedObject?.bank_name || bank.bank_name,
        is_primary: "0",
        is_active: "1",
        bank_code:  retrievedObject?.bank_code || bank.bank_code,
      };
      console.log("bank1111",bankreq)
      return bankreq;
    };


  const updateBankDetails = async () => {
    var payload = await MyFunction();

    let post_data = commonEncode.encrypt(JSON.stringify(payload));

    try {
      var config = {
        method: "post",
        url: DMF_UPDATEBANK_API_URL,
        data: post_data,
      };
      console.log("config",config)
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      var response_obj = JSON.parse(response);
      console.log("respone",response_obj)
      let error_code = response_obj.error_code;

      if (error_code == "100") {
        updateBank();
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: "Bank saved successfully.", type: "success" },
          autoClose: 3000,
        });
        setTimeout(() => {
          props.onNext();
        }, 4000);
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: response_obj.message, type: "error" },
          autoClose: 3000,
        });
      }
    } catch (e) {
      e.errorAlert();
    }
  };


  return (
    <>
      <ToastContainer limit={1} />
      <Row className="reverse">
        <div className="ProfileImg col-12 col-md-6">
          <div>
            <img src={Profile_1} alt="" />
          </div>
        </div>
        <div className="RightPanel col-12 col-md-6">
          <div className="rhl-inner">
            {showBack == true && (
              <FintooProfileBack
                title="Bank Account Details"
                onClick={() => props.onPrevious()}
              />
            )}
            <p className="">Confirm Your Bank Details</p>

            <div className="ConfBank">
              {Object.keys(bank).length ? (
                <tr>
                  <div className="BankConfrmDetails">
                    <div>
                      <img
                        src={`${process.env.REACT_APP_STATIC_URL}/media/bank_logo/${bankBseCode.bank_bse_code ? bankBseCode.bank_bse_code :bsecode}.png`}
                      />
                    </div>

                    <div style={{ marginLeft: "10px" }}>
                      <span className="BankCnfmName">{bank.bank_name}</span>
                      <p>
                        <small>{bank.bank_address}</small>
                      </p>
                      <table className="w-100 bank-tbl">
                        <tr>
                          <td>
                            <span className="CofmAccountNM">Account No. </span>
                          </td>
                          <td>
                            <span>{bank.bank_acc_no}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="CofmIfscCode">IFSC </span>
                          </td>
                          <td>
                            <span>{bank.bank_ifsc_code}</span>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div
                    className="Nominee_Identity_Last"
                    style={{ float: "right" }}
                  >
                    <FintooButton
                      onClick={() => {
                        handleSubmit();
                      }}
                      title="Next"
                    />
                  </div>
                </tr>
              ) : (
                <td className="d-flex text-center">
                  <strong>No results found</strong>
                </td>               
              )}
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}

export default ConfirmBank;

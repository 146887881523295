import React, { useRef, useEffect, useState } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import SimpleReactValidator from "simple-react-validator";
import { useLocation } from "react-router-dom";
const InternationalNumber = ({ value, disabled, onChange, onBlur, setCountryCode, setMobileValidation, fetchValidateMobileNo,setMobileErrorMsg, setErrorMsg }) => {
  const utilsScriptUrl =
    "https://cdn.jsdelivr.net/npm/intl-tel-input@18.0.3/build/js/utils.js";
  let iti = null;
  const inputRef = useRef(null);
  const [notice, setNotice] = useState(null);
 
  const [pageurl, setPageurl] = useState(false);
  const location = useLocation();
  const simpleValidator = useRef(new SimpleReactValidator());
  const errorMap = ["Please enter valid mobile number", "Invalid country code", "Please enter valid mobile number", "Please enter valid mobile number", "Please enter valid mobile number"];
  const handleSubmit = () => {
    if (iti.isValidNumber()) {
      setNotice("Valid number: " + iti.getNumber());
    } else {
      setNotice("Invalid");
    }
  };

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    onChange(onlyDigits);
    const input = document.querySelector("#phone");
    if (!input.value.trim())
    {
      setErrorMsg('')
    }
  };


  const [options, toggleOptions] = useState({
    allowDropdown: true,
    autoHideDialCode: true,
    initialCountry: "IN",
    separateDialCode: true,
    nationalMode: true,
    hadInitialPlaceholder: true,
    utilsScript: utilsScriptUrl,

    customPlaceholder: function (
      selectedCountryPlaceholder,
      selectedCountryData
    ) {
      return "e.g. " + selectedCountryPlaceholder;
    },
  });
  useEffect(() => {
    const input = document.querySelector("#phone");
    if (!input) return;
    const iti = intlTelInput(input, {
      ...options,
    });

    input.addEventListener("countrychange", function () {
      setCountryCode(iti.selectedCountryData.dialCode)
    });
    input.addEventListener('keyup', () => {
      // reset();
      if (input.value.trim()) {
        if (iti.isValidNumber()) {
          setErrorMsg('')
          setMobileErrorMsg('')
          setMobileValidation(true)

          // validMsg.classList.remove("hide");
        } else {
          // input.classList.add("error");

          const errorCode = iti.getValidationError();
          setErrorMsg(errorMap[errorCode])
          setMobileValidation(false)

          // errorMsg.innerHTML = errorMap[errorCode];
          // errorMsg.classList.remove("hide");
        }
      }
    });
    return () => {
      iti.destroy();
    };
  }, []);
  useEffect(() => {
    toggleOptions((o) => ({
      ...o,
      allowDropdown: !disabled,
      //disable dropdown when disable flag is set
    }));
  }, [disabled]);
  useEffect(() => {
    if ("pathname" in location) {
      setPageurl(location.pathname);
    }
  }, [location]);
  return (
    <div>
      <div className={ pageurl == "/web/sign-up" ? "newSignup" : null}>
        <input
          disabled={disabled}
          id="phone"
          // separateDialCode={true}
          style={{
            width: "100%",
          }}
          type="phone"
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          value={value}
          tabIndex="3"
          maxLength={15}
          name="mobileNo"
          // id="name"
          onChange={(e) => {
            checkInput(e);
          }}
          onBlur={() => {
            fetchValidateMobileNo()

          }}
          ref={inputRef}
          placeholder="Mobile Number*"
        />
      </div>
      {notice && <div>{notice}</div>}
    
    </div>
  );
};

export default InternationalNumber;

import { useEffect, useState } from "react";
import InvestmentStyle from "../../../../Assets/Datagathering/Graph/InvestmentStyle";
import Portfolio from "../../../../Assets/Datagathering/Graph/Portfolio";
import StockSectors from "../../../../Assets/Datagathering/Graph/StockSectors";
import { imagePath } from "../../../../constants";

function PortfolioData(props) {

    const ReactStarRating = props.ReactStarRating;
    const PARData = props.PARData;
    const portfolioHoldingData = props.PARData?.json_response?.Portfolio?.Holdings?.Holding;
    const assetAllocationData = props.PARData?.json_response?.Portfolio?.Breakdowns?.AssetAllocation?.['0']?.['Value'];
    const stockSectorData = props.PARData?.json_response?.Portfolio?.Breakdowns?.GlobalStockSector?.['Value'];
    const underlyingHoldingData = props.PARData?.json_response?.Portfolio?.UnderlyHoldings?.Holding;
    const equityStatisticsData = props.PARData?.json_response?.Portfolio?.EquityStatistics;
    const styleBoxData = props.PARData?.json_response?.Portfolio?.Breakdowns?.StyleBox?.Value;

    function getAssetType(data) {
        switch (data["@type"]) {
            case "1":
                return "Stock";
            case "2":
                return "Bond";
            case "3":
                return "Cash";
            case "4":
                return "Other";
            case "99":
                return "Not Classified";
        }
    };

    function getSectorType(data) {
        switch (data["GlobalSectorId"] || data['@type']) {
            case "101":
                return "Basic Materials"
            case "102":
                return "Consumer Cyclical"
            case "103":
                return "Financial Services"
            case "104":
                return "Real Estate"
            case "205":
                return "Consumer Defensive"
            case "206":
                return "Healthcare"
            case "207":
                return "Utilities"
            case "308":
                return "Communication Services"
            case "309":
                return "Energy"
            case "310":
                return "Industrials"
            case "311":
                return "Technology"
        }
    };

    function getSecurityType(data) {
        switch (data["SecurityType"]) {
            case "C0":
                return "Cash"
            case "FO":
                return "Funds"
            case "E0":
                return "Stocks"
            case "B0":
                return "Bonds"
            case "RE":
                return "Real Estate"
            case "NC":
                return "Not Classified"
            case "IX":
                return "Index"
            case "CA":
                return "Category"
            case "SA":
                return "SubAccount"
            case "MI":
                return "Miscellaneous"
            case "FC":
                return "Mutual Fund - Closed End"
            case "FE":
                return "Mutual Fund - ETF"
            case "FG":
                return "Euro Funds"
            case "FH":
                return "Mutual Fund - Hedge Funds"
            case "CB":
                return "Custom Benchmark"
            case "E":
                return "Equity"
            case "BT":
                return "Bond - Gov't/Treasury"
            case "B":
                return "Bonds"
        }
    };

    const cyclicSectorData = stockSectorData?.filter((data) =>
        ["101", "102", "103", "104"].includes(data["@type"])
    );

    const sensitiveSectorData = stockSectorData?.filter((data) =>
        ["308", "309", "310", "311"].includes(data["@type"])
    );

    const defensiveSectorData = stockSectorData?.filter((data) =>
        ["205", "206", "207"].includes(data["@type"])
    );

    // Calculate totals
    const cyclicSectorTotal = cyclicSectorData?.reduce(
        (total, data) => total + parseFloat(data["#text"]),
        0
    );

    const sensitiveSectorTotal = sensitiveSectorData?.reduce(
        (total, data) => total + parseFloat(data["#text"]),
        0
    );

    const defensiveSectorTotal = defensiveSectorData?.reduce(
        (total, data) => total + parseFloat(data["#text"]),
        0
    );

    console.log("underlyingHoldingData    ", underlyingHoldingData)

    return (
        <div>
            {PARData && PARData.json_response && Object.keys(PARData.json_response).length > 0 ?
                (<div>
                    <h4 className="rTitle">
                        <img
                            alt=""
                            src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/portfolio.svg"}
                        />{" "}
                        Portfolio
                    </h4>
                    <div className="rContent ">
                        The portfolio analysis report contains information related to Mutual
                        Funds, ULIP and Stocks which you have shared during the data gathering
                        process.
                    </div>
                    <div className="invest-recomm ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="recomm-box">
                                    <div className="green cardBox d-flex">
                                        <div>
                                            {" "}
                                            <img
                                                alt=""
                                                src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/portfolio-holdings.svg"}
                                            />
                                        </div>
                                        <div> Portfolio Holdings</div>
                                    </div>
                                    <div className="rContent ">
                                        <p>
                                            In this table we are showing the holdings of the entire
                                            Portfolio along with the ratings and their Returns of 1
                                            year, 3 year and 5 years. It also indicates as how much % a
                                            particular stock holds in the entire portfolio.
                                        </p>
                                    </div>
                                    <div className="table-responsive rTable">
                                        <table className="bgStyleTable asset-table text-center">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>Holding Portfolio Date</th>
                                                    <th className="th-rating">Rating</th>
                                                    <th style={{ width: 90 }}>1 Year</th>
                                                    <th>3 Years Annualised</th>
                                                    <th>5 Years Annualised</th>
                                                    <th>Weightage (%)</th>
                                                </tr>
                                                {/* {portfolioHoldingData && (
                                                    Object.keys(portfolioHoldingData)?.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="8" style={{ textAlign: "center" }}>
                                                                No data available.
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        portfolioHoldingData?.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{data["Name"]}</td>
                                                                <td>{getSecurityType(data)}</td>
                                                                <td>
                                                                    {data.PortfolioDate
                                                                        ? data.PortfolioDate.split("-").reverse().join("/")
                                                                        : ""}
                                                                </td>
                                                                <td ng-init="rating = phn.Rating" className="">
                                                                    <div className="star-rating" key={'str-' + index + '-' + parseInt(Number(data.Rating ?? 0))}>

                                                                        <ReactStarRating
                                                                            numberOfStar={5}
                                                                            numberOfSelectedStar={parseInt(Number(data.Rating ?? 0))}
                                                                            colorFilledStar="#005263"
                                                                            colorEmptyStar="gray"
                                                                            starSize="20px"
                                                                            spaceBetweenStar="10px"
                                                                            disableOnSelect={true}
                                                                            onSelectStar={(val) => { }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{parseFloat(data?.Performance?.Returns?.Return?.["5"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(data?.Performance?.Returns?.Return?.["6"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(data?.Performance?.Returns?.Return?.["7"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(data?.Weight).toFixed(2)}</td>
                                                            </tr>
                                                        ))
                                                    )
                                                )} */}
                                                {portfolioHoldingData && (
                                                    Array.isArray(portfolioHoldingData) && portfolioHoldingData.length > 0 ? (
                                                        portfolioHoldingData.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{data["Name"]}</td>
                                                                <td>{getSecurityType(data)}</td>
                                                                <td>
                                                                    {data.PortfolioDate
                                                                        ? data.PortfolioDate.split("-").reverse().join("/")
                                                                        : ""}
                                                                </td>
                                                                <td ng-init="rating = phn.Rating" className="">
                                                                    <div className="star-rating" key={'str-' + index + '-' + parseInt(Number(data.Rating ?? 0))}>
                                                                        <ReactStarRating
                                                                            numberOfStar={5}
                                                                            numberOfSelectedStar={parseInt(Number(data.Rating ?? 0))}
                                                                            colorFilledStar="#005263"
                                                                            colorEmptyStar="gray"
                                                                            starSize="20px"
                                                                            spaceBetweenStar="10px"
                                                                            disableOnSelect={true}
                                                                            onSelectStar={(val) => { }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{parseFloat(data?.Performance?.Returns?.Return?.["5"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(data?.Performance?.Returns?.Return?.["6"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(data?.Performance?.Returns?.Return?.["7"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(data?.Weight).toFixed(2)}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        portfolioHoldingData ? (
                                                            <tr>
                                                                <td>{portfolioHoldingData["Name"]}</td>
                                                                <td>{getSecurityType(portfolioHoldingData)}</td>
                                                                <td>
                                                                    {portfolioHoldingData.PortfolioDate
                                                                        ? portfolioHoldingData.PortfolioDate.split("-").reverse().join("/")
                                                                        : ""}
                                                                </td>
                                                                <td ng-init="rating = phn.Rating" className="">
                                                                    <div className="star-rating" key={'str-0-' + parseInt(Number(portfolioHoldingData.Rating ?? 0))}>
                                                                        <ReactStarRating
                                                                            numberOfStar={5}
                                                                            numberOfSelectedStar={parseInt(Number(portfolioHoldingData.Rating ?? 0))}
                                                                            colorFilledStar="#005263"
                                                                            colorEmptyStar="gray"
                                                                            starSize="20px"
                                                                            spaceBetweenStar="10px"
                                                                            disableOnSelect={true}
                                                                            onSelectStar={(val) => { }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{parseFloat(portfolioHoldingData?.Performance?.Returns?.Return?.["5"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(portfolioHoldingData?.Performance?.Returns?.Return?.["6"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(portfolioHoldingData?.Performance?.Returns?.Return?.["7"]?.["#text"]).toFixed(2)}</td>
                                                                <td>{parseFloat(portfolioHoldingData?.Weight).toFixed(2)}</td>
                                                            </tr>
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="8" style={{ textAlign: "center" }}>
                                                                    No data available.
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="recomm-box">
                                    <div className="green cardBox d-flex">
                                        <div>
                                            {" "}
                                            <img
                                                alt=""
                                                src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/assset-allocation.svg"}
                                            />
                                        </div>
                                        <div> Asset Allocation</div>
                                    </div>
                                    <div className="rContent ">
                                        <p>
                                            The pie chart and table detail your portfolio's exposure to
                                            4 asset classes, namely stocks, bonds, cash and others. The
                                            ‘others’ category represents an asset class, such as real
                                            estate. The tables adjacent to the pie chart mentions the
                                            asset allocation percentages, as well as the component long
                                            positions (assets) and short/marginal positions
                                            (liabilities) of your portfolio.
                                        </p>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <div className="col-md-11 ">
                                                <div className="text-center">
                                                    <h4>Portfolio</h4>
                                                </div>
                                                <div>
                                                    {assetAllocationData && (
                                                        <Portfolio
                                                            assetAllocationData={assetAllocationData}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="table-responsive rTable">
                                                <table className="bgStyleTable asset-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Asset Allocation</th>
                                                            <th>Portfolio (%)</th>
                                                        </tr>
                                                        {assetAllocationData && (
                                                            assetAllocationData.length === 0 ? (
                                                                <tr>
                                                                    <td colSpan="8" style={{ textAlign: "center" }}>
                                                                        No data available.
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                assetAllocationData.map((data, index) => (
                                                                    <tr key={index}>
                                                                        <td>{getAssetType(data)}</td>
                                                                        <td>{parseFloat(data["#text"]).toFixed(2)}</td>
                                                                    </tr>
                                                                ))
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {stockSectorData && (
                                <div className="col-md-12">
                                    <div className="recomm-box">
                                        <div className="green cardBox d-flex">
                                            <div>
                                                {" "}
                                                <img
                                                    alt=""
                                                    src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/stock-sectors.svg"}
                                                />
                                            </div>
                                            <div> Stock Sectors </div>
                                        </div>

                                        <div className="rContent ">
                                            <p>
                                                This table lists the percentage of your portfolio’s equity
                                                assets that is invested in each of the three super sectors
                                                (information, service, and manufacturing economies) and the
                                                12 major industry sub-classifications.The sector graph that
                                                accompanies the table takes into account three parameters:
                                                cyclical, sensitive and defensive.
                                            </p>
                                        </div>
                                        <div className="row align-items-center">
                                            <div id="assetMatrix" className="col-md-6 mt-5">
                                                <div className="text-center">
                                                    <h4>Stock Sectors</h4>
                                                </div>
                                                <div className="mt-1">
                                                    <StockSectors
                                                        cyclicSectorTotal={cyclicSectorTotal}
                                                        sensitiveSectorTotal={sensitiveSectorTotal}
                                                        defensiveSectorTotal={defensiveSectorTotal}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <table className="bgStyleTable asset-table" id="stockTable">
                                                    <tbody>
                                                        <tr>
                                                            <th>Cyclical</th>
                                                            <th>Stock (%)</th>
                                                        </tr>
                                                        {cyclicSectorData?.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{getSectorType(data)}</td>
                                                                <td>{parseFloat(data["#text"]).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr className="outline bold total-value">
                                                            <td>Total</td>
                                                            <td>{cyclicSectorTotal?.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <table className="bgStyleTable asset-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Sensitive</th>
                                                            <th>Stock (%)</th>
                                                        </tr>
                                                        {sensitiveSectorData?.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{getSectorType(data)}</td>
                                                                <td>{parseFloat(data["#text"]).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr className="outline bold total-value">
                                                            <td>Total</td>
                                                            <td>{sensitiveSectorTotal?.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-6">
                                                <table className="bgStyleTable asset-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Defensive</th>
                                                            <th>Stock %</th>
                                                        </tr>
                                                        {defensiveSectorData?.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{getSectorType(data)}</td>
                                                                <td>{parseFloat(data["#text"]).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                        <tr className="outline bold total-value">
                                                            <td>Total</td>
                                                            <td>{defensiveSectorTotal?.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {equityStatisticsData && (
                                <div className="col-md-12">
                                    <div className="recomm-box">
                                        <div className="green cardBox d-flex">
                                            <div>
                                                {" "}
                                                <img
                                                    alt=""
                                                    src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/investment-styles.svg"}
                                                />
                                            </div>
                                            <div> Investment Style</div>
                                        </div>
                                        <div className="rContent ">
                                            <p>
                                                Through indicative ratios such as the price/book ratio,
                                                price/earnings ratio and price/cashflow ratio, this section
                                                explains the style in which equity investments are being
                                                made.
                                            </p>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-5">
                                                <div
                                                    id="investmentStyle"
                                                    data-highcharts-chart={3}
                                                    style={{ overflow: "hidden" }}
                                                >
                                                    {styleBoxData && (
                                                        <InvestmentStyle
                                                            styleBoxData={styleBoxData}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="table-responsive rTable">
                                                    <table className="bgStyleTable asset-table">
                                                        <tbody>
                                                            <tr>
                                                                <th>Equity Style</th>
                                                                <th>Portfolio (%)</th>
                                                            </tr>
                                                            {!equityStatisticsData?.["ProspectiveBookValueYield"] &&
                                                                !equityStatisticsData?.["ProspectiveEarningsYield"] &&
                                                                !equityStatisticsData?.["ProspectiveCashFlowYield"] && (
                                                                    <tr>
                                                                        <td colSpan="2" style={{ textAlign: "center" }}>
                                                                            No data available.
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            {equityStatisticsData?.["ProspectiveBookValueYield"] && (
                                                                <tr>
                                                                    <td>Price/Book Ratio</td>
                                                                    <td className="">{parseFloat(equityStatisticsData?.["ProspectiveBookValueYield"]["#text"]).toFixed(2)}</td>
                                                                </tr>
                                                            )}
                                                            {equityStatisticsData?.["ProspectiveEarningsYield"] && (
                                                                <tr>
                                                                    <td>Price/Earnings</td>
                                                                    <td className="">{parseFloat(equityStatisticsData?.["ProspectiveEarningsYield"]["#text"]).toFixed(2)}</td>
                                                                </tr>
                                                            )}
                                                            {equityStatisticsData?.["ProspectiveCashFlowYield"] && (
                                                                <tr>
                                                                    <td>Price/Cashflow</td>
                                                                    <td className="">{parseFloat(equityStatisticsData?.["ProspectiveCashFlowYield"]["#text"]).toFixed(2)}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {underlyingHoldingData && (
                                <div className="col-md-12">
                                    <div className="recomm-box">
                                        <div className="green cardBox d-flex">
                                            <div>
                                                {" "}
                                                <img
                                                    alt=""
                                                    src={imagePath + "https://static.fintoo.in/static/assets/img/reports/current-investments/top-underlying-holdingss.svg"}
                                                />
                                            </div>
                                            <div> Top 10 Underlying Holdings</div>
                                        </div>
                                        <div className="rContent">
                                            <p>
                                                This section shows you the top 10, most heavily-weighted
                                                underlying holdings in your portfolio. It pinpoints the
                                                percentage of assets that each holding represents, the
                                                security type, the sector classification, and the country of
                                                origin.
                                            </p>
                                        </div>
                                        <table className="bgStyleTable asset-table">
                                            <tbody>
                                                <tr>
                                                    <th>Assets (%)</th>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>Sector</th>
                                                    <th>Country</th>
                                                </tr>
                                                {/* {underlyingHoldingData?.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: "center" }}>
                                                            No data available.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    underlyingHoldingData
                                                        ?.slice(0, 10) // Select only the first 10 elements
                                                        ?.map((data, index) => (
                                                            <tr key={index}>
                                                                <td>{parseFloat(data.Weight).toFixed(2)}</td>
                                                                <td>{data.Name}</td>
                                                                <td>{getSecurityType(data)}</td>
                                                                <td>{getSectorType(data)}</td>
                                                                <td>{data.CountryId}</td>
                                                            </tr>
                                                        ))
                                                )} */}
                                                {underlyingHoldingData?.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: "center" }}>
                                                            No records found.
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    Array.isArray(underlyingHoldingData) ? (
                                                        underlyingHoldingData
                                                            .slice(0, 10) // Select only the first 10 elements
                                                            .map((data, index) => (
                                                                <tr key={index}>
                                                                    <td>{parseFloat(data.Weight).toFixed(2)}</td>
                                                                    <td>{data.Name}</td>
                                                                    <td>{getSecurityType(data)}</td>
                                                                    <td>{getSectorType(data)}</td>
                                                                    <td>{data.CountryId}</td>
                                                                </tr>
                                                            ))
                                                    ) : (
                                                        <tr>
                                                            <td>{parseFloat(underlyingHoldingData.Weight).toFixed(2)}</td>
                                                            <td>{underlyingHoldingData.Name}</td>
                                                            <td>{getSecurityType(underlyingHoldingData)}</td>
                                                            <td>{getSectorType(underlyingHoldingData)}</td>
                                                            <td>{underlyingHoldingData.CountryId}</td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>

                                        </table>
                                        <div
                                            style={{
                                                height: 50,
                                                clear: "both",
                                                width: 100,
                                            }}
                                        >
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                ) : (
                    <div className="no-data-found text-md-center">
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-10">
                                    <img
                                        alt="Data not found"
                                        src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                                    />
                                    <p>
                                        Since you missed to fill in the required information which is
                                        needed here, we are not able to show you this section. Kindly
                                        click on below button to provide all the necessary inputs.
                                        Providing all the information as asked will ensure more accurate
                                        financial planning report. Once you fill in the data, same will
                                        be reflected here.
                                    </p>
                                    <a
                                        href={process.env.PUBLIC_URL + "/datagathering/assets-liabilities"}
                                        target="_blank"
                                        className="link"
                                    >
                                        Complete Assets &amp; Liabilities
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

        </div>
    );
}

export default PortfolioData;
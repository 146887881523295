import { useEffect,useState, useRef } from 'react';
import styles from './style.module.css';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {

    const { pathname, hash } = useLocation();
    const eleRef = useRef(null);
    const location = useLocation();
    const [pageurl, setPageurl] = useState();
    useEffect(()=> {
        
        if(window.innerWidth <= 768) {
            eleRef.current.style.display = 'none';
        } else {
            handleScroll();
            window.addEventListener("scroll", handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, []);

    const handleScroll = (e) => {
        
        if(window.pageYOffset > 200) {
            eleRef.current.style.display = 'block';
        } else {
            eleRef.current.style.display = 'none';
        }
    }
    useEffect(() => {
        if ("pathname" in location) {
          setPageurl(location.pathname);
        }
      }, [location]);
    return (<>
        <div ref={eleRef}  className={`${ pageurl == "/web/financial-health-checkup" ?  styles['scrolltopdiv1'] :  styles['scrolltopdiv']}`} onClick={()=> {
            window.scrollTo(0, 0);
        }}><img src={require('./up-left-arrow.png')} /></div>
    </>);
}
export default ScrollToTop;
import Select from "react-select";
import FintooDatePicker from "../../../../components/HTML/FintooDatePicker";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import style from './insuranceForm.module.css'
import { formatDatefun } from "../../../../Utils/Date/DateFormat";
import moment from "moment";

const RecurringMaturityForm = (props) => {
    const [, forceUpdate] = useState();

    const options_frequency = [
        { value: "1", label: "Monthly" },
        { value: "2", label: "Quarterly" },
        { value: "3", label: "Half Yearly" },
        { value: "4", label: "Yearly" },
        { value: "5", label: "Once in 1 Years" },
        { value: "6", label: "Once in 2 Years" },
        { value: "7", label: " Once in 3 Years" },
        { value: "8", label: "  Once in 4 Years" },
        { value: "9", label: "  Once in 5 Years" },
    ]

    const simpleValidator = useRef(new SimpleReactValidator());

    simpleValidator.current.purgeFields();

    useEffect(() => {
        simpleValidator.current.allValid();
        simpleValidator.current.showMessages();
        forceUpdate(1);
    }, [])

    // useEffect(()=>{console.log("12345", props.data)},[props.data])

    const addNewForm = () => {
        forceUpdate(2);
        if (simpleValidator.current.allValid()) {
            props.add();
        }
    }


    


    return (
        <div className={`col-12 ${style.recurringFormContainer}`}>
            <div className="col-12 col-md-11" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className="col-6 col-md-3 px-2">
                    <div className="">
                        <span className="lbl-newbond">Start date *</span>
                        <br />
                        <div className="bonds-datepicker">
                            <FintooDatePicker
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                name="insurance_start_date"
                                dateFormat="yyyy/MM/dd"
                                customClass="datePickerDMF"
                                selected={'insurance_start_date' in props.data ? moment(props.data.insurance_start_date, "DD/MM/YYYY").toDate() : ""}
                                onChange={(date) => props.update(props.id, 'insurance_start_date', formatDatefun(date))}
                            />

                        </div>
                        {simpleValidator.current.message('insurance_start_date', props.data.insurance_start_date, 'required')}
                    </div>
                </div>
                <div className="col-6 col-md-3 px-2">
                    <div className="">
                        <span className="lbl-newbond">End date *</span>
                        <br />
                        <div className="bonds-datepicker">
                            <FintooDatePicker
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                autoComplete="off"
                                name="insurance_end_date"
                                dateFormat="yyyy/MM/dd"
                                customClass="datePickerDMF"
                                selected={"insurance_end_date" in props.data ? moment(props.data.insurance_end_date, "DD/MM/YYYY").toDate() : ""}
                                onChange={(date) => props.update(props.id, 'insurance_end_date', formatDatefun(date))}
                            />
                        </div>
                        {simpleValidator.current.message('insurance_end_date', props.data.insurance_end_date, 'required')}
                    </div>
                </div>
                <div className="col-6 col-md-3 px-2">
                    <div className="">
                        <span className="lbl-newbond">Amount *</span>
                        <br />
                        <input
                            placeholder="Enter Amount"
                            className={` w-100 fntoo-textbox-react`}
                            type="text"
                            name="insurance_recurring_amount"
                            maxLength={10}
                            value={props.data.insurance_recurring_amount}
                            onChange={(e) => props.update(props.id, e.target.name, e.target.value, true)}
                        />
                    </div>
                    {simpleValidator.current.message('insurance_recurring_amount', props.data.insurance_recurring_amount, 'required|numeric|min:0,num')}
                </div>
                <div className="col-6 col-md-3 px-2">
                    <div className="">
                        <span className="lbl-newbond">
                            Frequency *
                        </span>
                        <br />
                        <Select
                            className="fnto-dropdown-react"
                            classNamePrefix="sortSelect"
                            isSearchable={false}
                            options={options_frequency}
                            value={(options_frequency.find(item => item.value === props.data.insurance_frequency))}
                            onChange={(e) => props.update(props.id, 'insurance_frequency', e.value)}
                        />
                    </div>
                    {simpleValidator.current.message('insurance_frequency', props.data.insurance_frequency, 'required')}
                </div>
            </div>
            <div className="col-12 col-md-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '1rem' }}>
                <div className="col-1 col-md-6" style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', color: '#21a6de' }}>
                    <i onClick={() => props.delete(props.id)} className="fa-solid fa-trash-can"></i>
                </div>
                {
                    props.isLast && <div className="col-1 col-md-6" style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', color: '#21a6de' }}>
                        <i onClick={addNewForm} className={props.id != 4 ? "fa-solid fa-circle-plus" : "disabled fa-solid fa-circle-plus"}></i>
                    </div>
                }

            </div>
        </div>
    );
};
export default RecurringMaturityForm;

import Checked from "./check_01.png";
import Unchecked from "./check_02.png";
import './style.css';

const FintooCheckbox = (props) => {
    return (
        <div className="fintoo-checkbox" onClick={()=> props.onChange()}>
            <img src={props.checked ? Checked : Unchecked} />
            <div className={(props.title == "AMC") ? "fc-title-amc" : "fc-title"}>{props.title}</div> 
        </div>
    );
}

export default FintooCheckbox;

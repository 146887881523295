import React from "react";
import { makePositive, rsFilter } from "../../../common_utilities";
import RetirementCorpus from "../../../Assets/Datagathering/Graph/RetirementCorpus";
import { imagePath } from "../../../constants";

function RetirementCorpusPage(props) {
  const graphdata = props?.data?.graphdata ? props?.data?.graphdata : [];
  const colorArr = props?.data?.colorArr ? props?.data?.colorArr : [];

  const total_corpus_required = props?.data?.total_corpus_required ? props?.data?.total_corpus_required : 0;
  const total_income_corpus = props?.data?.total_income_corpus ? props?.data?.total_income_corpus : 0;

  const shortsurptext = props?.data?.ShortSurpText ? props?.data?.ShortSurpText : [];
  const ShortSurpValue = props?.data?.ShortSurpValue ? props?.data?.ShortSurpValue : [];
  
  const linkedGoalAssets = props?.data?.linkedgoalassets ? props?.data?.linkedgoalassets : [];
  const linkedbyfintoo = props?.data?.linkedbyfintoo ? props?.data?.linkedbyfintoo : [];
  const additionalInv = props?.data?.additionalInv ? props?.data?.additionalInv : [];

  return (
    <>
      {total_corpus_required > 0 ? (
      <div className="pageHolder retirementCorpus ">
      <div>
        <h4 className="rTitle">
          <img alt="retirement corpus" className="title-icon" 
          src={imagePath + "https://static.fintoo.in/static/assets/img/reports/retirement-planning/retirement-corpus.svg"}
          />{" "}
          Retirement corpus
        </h4>
        <div className="rContent ">
          <p>This is that amount you’ll need to live comfortably all through your non-earning, retirement years. To arrive at your retirement corpus our experts determine the future cost of all your expenses, while also taking into account the rate of inflation.</p>
        </div>
        <div className="table-responsive rTable">
          <table className="bgStyleTable">
            <tbody>
              <tr>
                <th>Particulars</th>
                <th>Amount (₹)</th>
              </tr>
              <tr>
                <td>Corpus required for post retirement expenses</td>
                <td className="">{rsFilter(parseInt(total_corpus_required))}</td>
              </tr>
              <tr className="bold top-line total-value">
                <td>Total Corpus Required (A)</td>
                <td>{rsFilter(parseInt(total_corpus_required))}</td>
              </tr>
              {total_income_corpus !='' ? (
              <tr className="bold top-line total-value">
                <td>Corpus Created for post retirement Income (B)</td>
                <td>{rsFilter(parseInt(total_income_corpus))}</td>
              </tr>
              ):null}
              {linkedGoalAssets !='' ? (
              <tr className="bold top-line total-value">
                <td>Linkages Asset</td>
                <td></td>
              </tr>
              ):null}
              {linkedGoalAssets && linkedGoalAssets.length > 0 && linkedGoalAssets.map((item, index) => (
                <tr key={index}>
                  <td>{item["name"]}</td>
                  <td>{rsFilter(parseInt(item["asset_future_value"]))}</td>
                </tr>
              ))}
              <tr className="bold top-line total-value">
                <td style={{ fontWeight: "bold" }} className="">Total corpus created (C)</td>
                {linkedGoalAssets && linkedGoalAssets.length > 0  ? (
                <td>{rsFilter(parseInt(linkedGoalAssets.reduce((total, item) => total + item['asset_future_value'], 0)))}</td>
                ):<td>{rsFilter(parseInt(0))}</td>}
              </tr>
              <tr className="bold top-line total-value">
                <td style={{ fontWeight: "bold" }} >{shortsurptext} ( Corpus Required - Corpus Created ) (D = ((A-B)-C))</td>
                {ShortSurpValue >= 0 ? (
                  <td style={{ fontWeight: "bold", color:"black"}} >{rsFilter(parseInt(ShortSurpValue))}</td>
                ):<td style={{ fontWeight: "bold", color:"red"}} >({rsFilter(parseInt(Math.abs(ShortSurpValue)))})</td>}
              </tr>
            </tbody>
          </table>
        </div>

        <h4 className="rTitle d-flex align-items-center mt-5">
          <img className="title-icon"
           src={imagePath + "https://static.fintoo.in/static/assets/img/reports/retirement-planning/retirement-corpus.svg"}
           />{" "}
          <span>{" "}Fintoo Recommends</span>
        </h4>
        <div className="rContent ">
          <p>You are now aware of the goal amount, as well as whether you have a surplus or deficit for the goal once you link certain assets to it. Note that this calculation takes into account the future cost. This means that it determines the amount required for the goal on taking into account the impact of inflation.</p>
        </div>
        <div className="invertment-goal-mapping">
          <div className="container " style={{ marginTop: 80 }}>
            {linkedbyfintoo[0].linked_assets_by_fintoo !='' ? (
            <div className="recommen_sec_div">
              {linkedbyfintoo[0].linked_assets_by_fintoo !='' ? (
              <div className="rec_head_div" style={{ left: "40%" }}>
                <i></i>
                <span>Fintoo Recommends</span>
              </div>
              ):null}
              {linkedbyfintoo[0].linked_assets_by_fintoo !='' ? (
              <div className="inner_text_div">
                <p className="mb-3" style={{ fontSize: "1rem",fontWeight: "600",}}>
                  As you can see in the table below, there are
                  certain goals that you aren’t able to achieve
                  fully. To help you remedy this, we have linked
                  your currently unlinked assets to these goals.
                  Take a look at the table below for more
                  information.
                </p>
              </div>
              ):null}
              {linkedbyfintoo[0].linked_assets_by_fintoo !='' && (linkedbyfintoo[0].total_asset_value_by_fintoo > 0 || linkedbyfintoo[0].total_new_investment_by_fintoo > 0)? (
              <div className="table_div ">
                <table className="bgStyleTable splitForPrint">
                  <thead>
                    <tr>
                      <th style={{ borderRight: "none" }}>Assets Linked by Fintoo</th>
                      <th>Future Value of Assets (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {linkedbyfintoo[0].linked_assets_by_fintoo.map((item, index) => (
                    <tr className="top-line">
                      <td style={{ fontWeight: 700 }}>{item['asset_name']}</td>
                      <td style={{ fontWeight: 700 }}>{rsFilter(parseInt(item['asset_future_value']))}</td>
                    </tr>
                    ))}
                    <tr className="top-line bold">
                      <td className="">Gross Total (₹)</td>
                      <td>{rsFilter(parseInt(linkedbyfintoo[0].total_asset_value_by_fintoo))}</td>
                    </tr>
                    <tr className="bold top-line ">
                      {linkedbyfintoo[0].diff_afterlinkange >= 0 ? (
                        <td style={{ fontWeight: "bold", color:"black"}}>Surplus</td>
                      ):<td style={{ fontWeight: "bold", color:"red"}}>(Shortfall)</td>}
                      {linkedbyfintoo[0].diff_afterlinkange >= 0 ? (
                        <td style={{ fontWeight: "bold", color:"black"}} >{rsFilter(parseInt(linkedbyfintoo[0].diff_afterlinkange))}</td>
                      ):<td style={{ fontWeight: "bold", color:"red"}} >({rsFilter(parseInt(Math.abs(linkedbyfintoo[0].diff_afterlinkange)))})</td>}
                    </tr>
                  </tbody>
                </table>
              </div>
              ):null}
              
              <br />
              <br />

              {additionalInv !='' && additionalInv.reduce((total, item) => total + item['fv'], 0) > 0 ? (
              <div className="mt-4">
                <h4 className="rTitle text-center">
                  <img src={imagePath + "https://images.fintoo.in/static/assets/img/reports/goal-analysis/additional-investment.svg"}
                   alt=""></img>
                  Addition Investment for the Goal
                </h4>
                <div className="inner_text_div">
                  <p className="mb-3" style={{ fontSize: "1rem", fontWeight: "600", }}>
                    As your current investments are not sufficient
                    to achieve your goal, we recommend that you make
                    fresh investments to accumulate the necessary
                    finance. Based on your risk profile and the
                    tenure of the goal, here are the options our
                    experts consider best for you.
                  </p>
                </div>
                <div className="table_div ">
                  <table className="bgStyleTable">
                    <thead>
                      <tr>
                        <th colSpan={6} className="text-center">
                          Monthly Investment OR Lumpsum Investment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="outline">
                        <td className="bold">Asset Type </td>
                        <td className="bold">Allocation</td>
                        <td className="bold">Return Percentage</td>
                        <td className="bold">Monthly Investment (₹)</td>
                        <td className="bold">Lumpsum Investment (₹)</td>
                        <td className="bold">Future Value (₹)</td>
                      </tr>
                      {additionalInv.map((item, index) => (
                        <tr key={index}>
                          <td className="bold">{item["name"]}</td>
                          <td className="bold">{item["allocation_per"]}%</td>
                          <td className="bold">{item["return_rate"]}%</td>
                          <td className="bold">{rsFilter(parseInt(item["pmt"]))}</td>
                          <td className="bold">{rsFilter(parseInt(item["pv"]))}</td>
                          <td className="bold">{rsFilter(parseInt(item["fv"]))}</td>
                        </tr>
                      ))}
                      <tr className="bold top-line total-value">
                        <td colSpan={3}>Total</td>
                        <td>{rsFilter(parseInt(additionalInv.reduce((total, item) => total + item['pmt'], 0)))}</td>
                        <td>{rsFilter(parseInt(additionalInv.reduce((total, item) => total + item['pv'], 0)))}</td>
                        <td>{rsFilter(parseInt(additionalInv.reduce((total, item) => total + item['fv'], 0)))}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              ):null}
            </div>
            ):null}
          </div>
          <p style={{ height: "2rem", }}></p>

          {graphdata !='' ? (
          <div className="graphSection rGraph row justify-content-center">
            <h3 className="text-center">Retirement Goal Recommendation</h3>
            <div id="retirementCorpusGraph" className="col-md-10" data-highcharts-chart={0} style={{overflow: "hidden",backgroundColor: "#fff",}}>
              <RetirementCorpus graphdata={graphdata} colorArr={colorArr}/>
            </div>
          </div>
          ):null}
          <div className="container" style={{marginTop: "4rem",}}>
            <div className="notes_sec_div mt-5">
              <div className="notes_head_div">
                <i
                  style={{
                    top: "-11px !important",
                    left: "-5px !important",
                  }}
                />
                <span>Notes</span>
              </div>
              <div className="notes_text_div">
                <p
                  style={{ fontStyle: "italic", color: "#787878" }}
                >
                  The Rate Of Return (ROR) for the Equity Asset
                  Class Eg. Shares, Equity Mutual Funds, PMS, ESOPs
                  etc. linked to achieve your goals is assumed to be
                  12.0% per annum, as per the market standards or as
                  manually edited by you.
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      ):
      <>
        <div className="no-data-found text-center">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-10">
                <img
                  src={imagePath + "https://static.fintoo.in/static/assets/img/data-not-found.svg"}
                  alt="Data not found"
                />
                <p>
                  Since you missed to fill in the required information which
                  is needed here, we are not able to show you this section.
                  Kindly click on below button to provide all the necessary
                  inputs. Providing all the information as asked will ensure
                  more accurate financial planning report. Once you fill in
                  the data, same will be reflected here.
                </p>
                <a
                  href="/web/datagathering/income-expenses"
                  target="_blank"
                  className="link"
                >
                  Complete Retirement expense
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </>
  );
}
export default RetirementCorpusPage;

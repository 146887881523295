import React from "react";

function ExploreStock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "17px"}
      height={props.height || '17px'}
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
    >
      <path
        fill="#24a7df"
        d="M256 0C114.833 0 0 114.833 0 256s114.833 256 256 256 256-114.853 256-256S397.167 0 256 0zm0 472.341c-119.275 0-216.341-97.046-216.341-216.341S136.725 39.659 256 39.659c119.295 0 216.341 97.046 216.341 216.341S375.275 472.341 256 472.341z"
        data-original="#000000"
        transform="rotate(90 256 256)"
      ></path>
      <path
        fill="#24a7df"
        d="M369.227 283.365l-99.148-99.148c-7.734-7.694-20.226-7.694-27.96 0l-99.148 99.148c-6.365 7.416-6.365 18.382 0 25.798 7.119 8.309 19.651 9.28 27.96 2.161L256 226.256l85.267 85.069c7.734 7.694 20.226 7.694 27.96 0 7.694-7.734 7.694-20.227 0-27.96z"
        data-original="#000000"
        transform="rotate(90 256 256)"
      ></path>
    </svg>
  );
}

export default ExploreStock;

import { useState, useEffect ,useRef} from "react";
import "react-responsive-modal/styles.css";
import Link from "../../../MainComponents/Link";
import Profile_1 from "../../../Assets/04_Add_nominee.svg";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import * as constClass from "../../../../constants";

import { Container, Form ,Row, Col, FormGroup, FormControl } from "react-bootstrap";
import { DMF_BASE_URL } from "../../../../constants";
import axios from "axios";
import OTPInput, { ResendOTP } from "otp-input-react";
import commonEncode from "../../../../commonEncode";
import ProgressBar from "@ramonak/react-progress-bar";

import { SegmentedControl } from "segmented-control";
import Back from "../../../Assets/left-arrow.png";
import "../Fatca/style.css";
import FintooButton from "../../../HTML/FintooButton";
import FintooProfileBack from "../../../HTML/FintooProfileBack";
import { FaTrashAlt, FaEdit, FaMinusCircle } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import WhiteOverlay from "../../../../components/HTML/WhiteOverlay";
import {
  CheckSession,
  apiCall,
  successAlert,
  errorAlert,
  loginRedirectGuest,
  getUserId,
  fetchEncryptData,
} from "../../../../common_utilities";
import { Next } from "react-bootstrap/esm/PageItem";
import FintooDatePicker from "../../../HTML/FintooDatePicker";

function NomineeDetails(props) {
  const [validated, setValidated] = useState(false);
  const [totalAdded, setTotalAdded] = useState(0);
  const [addMore, setAddMore] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editNumber, setEditNumber] = useState(null);
  const [wantToAddNominee, setWantToAddNominee] = useState("no");
  const [allNominee, setAllNominee] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [fullname, setFullname] = useState("");
  const [percentage, setPercentage] = useState("");
  const [relation, setRelation] = useState("");
  const [dob, setDob] = useState("");
  const [guardianName, setGuardianName] = useState("");
  

  const [error, setError] = useState({});
  const showBack = useSelector((state) => state.isBackVisible);
  const [dynamicNomineeList, getDynamicNomineeList] = useState([]);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState("");
  const [pageload, setpage] = useState("");
  const [nomineeid, setnomineeid] = useState("");
  const [editThis, setEditThis] = useState({});
  const [updateSeg, setUpdateSeg] = useState(0);
  const [delnominee, setdelnominee] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [OTP, setOTP] = useState("");
  const [showdata, setShowdata] = useState(false);
  // const [show, setShow] = useState(false);
  const [mobile1,setmobile] =useState("")
  const [email1 ,setemail] = useState("")
  const [count, setCount] = useState(120);
  const [GeneratedSmsOTP, setGeneratedSmsOTP] = useState("");
  const [generatedemailotp, setGeneratedEmailOTP] = useState("");
  // const handleClose = () => setShow(false);
  const handleClosedata = () => setShowdata(false);
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const randomOTP = useRef(Math.floor(Math.random() * 90000) + 10000);

  useEffect(() => {
    onLoadInIt();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    nomineelist();
  }, [mobile1,email1]);

  const onLoadInIt = async () => {
    var data = { user_id: user_id };
    try {
      var data = commonEncode.encrypt(JSON.stringify(data));
      var config = {
        method: "post",
        url: DMF_BASE_URL + "api/user/getuserdetails",
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);
      setUserDetails(JSON.parse(response)["data"]);
      setmobile(JSON.parse(response)["data"]['mobile'])
      setemail(JSON.parse(response)["data"]['email'])
      
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went Wrong...", type: "error" },
      });
    }
  };

  const startTimer = () => {
    setOTP("");
    // updatesetOTP("");
    clearInterval(timer.current.obj);
    timer.current.counter = timer.current.default;
    setCount(timer.current.counter);
    timer.current.obj = setInterval(() => {
      if (timer.current.counter == 0) {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
        return;
      }
      timer.current.counter = timer.current.counter - 1;
      setCount(timer.current.counter);
    }, 1000);
  };
  
  

  let navDynamicNext = "";
  let navDynamicPrev = "";
  if (userDetails.residential_status == "1") {
    navDynamicNext = "Bank";
    navDynamicPrev = "FatcaAll";
  } else if (
    userDetails.residential_status == "2" ||
    userDetails.residential_status == "3"
  ) {
    navDynamicNext = "Bank";
    navDynamicPrev = "FatcaAdd";
  }

  var user_id = getUserId();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    props.onNext(navDynamicNext);
  };

  const [theArray, setTheArray] = useState([]);
  const addEntryClick = () => {
    if (theArray.length < 2)
      setTheArray([...theArray, `Entry ${theArray.length}`]);
  };

  const removeNominee = () => {
    let ta = theArray;
    ta.shift();
    setTheArray([...ta]);
  };
  const cancelForm = () => {
    setAddMore(false);
    setEditMode(false);
    setEditNumber(null);
  };

  const validateForm = () => {
    const tempEerror = {};
    if (fullname.trim() == "") tempEerror.fullname = "Name is required";
    if(fullname.length <2 && fullname != "") tempEerror.fullname = "Please Provide Valid nominee first name "
    if (Boolean(dob) == false) tempEerror.dob = "Date of birth is required";
    if (guardianName.trim() == "" && moment().diff(moment(dob), "years") < 18 && guardianName.length<2)
      tempEerror.guardianName = "Parent/Guardian name is required. ";
    if (relation.trim() == "") tempEerror.relation = "Relation is required";
    if(guardianName.length<2 && guardianName != "") tempEerror.guardianName = "Please Provide Valid nominee guardian name!";

    setError({ ...tempEerror });
    return !Boolean(Object.keys(tempEerror).length);
  };
  const editData = (v, index) => {
    setEditThis({
      nominee_first_name: v.nominee_first_name,
      nominee_dob: v.nominee_dob,
      nominee_guardian_name: v.nominee_guardian_name,
      nominee_relation: v.nominee_relation,
      percentage,
    });
    setEditNumber(index);
  };
  const saveData = (index) => {
    if (validateForm() == true) {
      addnomineedetails();
    }
  };
  const deleteData = (index) => {
    var old = allNominee;
    old.splice(index, 1);
    setAllNominee([...old]);
  };

  const addNewRecord = () => {
    if (validateForm() == true) {
      addnomineedetails();
    }
  };

  const resetForm = () => {
    setFullname("");
    setPercentage("");
    setDob("");
    setGuardianName("");
    setRelation("");
    setEditNumber(null);
  };

  function refreshPage() {
    window.location.reload(true);
  }

  const nomineelist = async () => {
    var user_id = getUserId();
    try {
      var dynamicNomineeData = { user_id: user_id };
      var data = commonEncode.encrypt(JSON.stringify(dynamicNomineeData));
      var config = {
        method: "post",
        url: constClass.DMF_NOMINEELIST_API_URL,
        data: data,
      };
      var res = await axios(config);
      var response = commonEncode.decrypt(res.data);

      var allNominee = JSON.parse(response)["data"];

      if (allNominee.length) {
        allNominee = allNominee[0];
        setFullname(allNominee["nominee_first_name"]);
        setPercentage(allNominee["nominee_applicable"]);
        setDob(allNominee["nominee_dob"]);
        setGuardianName(allNominee["nominee_guardian_name"] ? allNominee["nominee_guardian_name"] : '');
        setRelation(allNominee["nominee_relation"]);
        setnomineeid(allNominee["nominee_id"]);
      }
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went Wrong...", type: "error" },
      });
    }
  };

  var load = allNominee.length;
  if (load > 0) {
    var set = Boolean(true);
  } else {
    var set = Boolean(false);
  }

  var addnominee_req = {
    nominee_first_name: fullname,
    nominee_dob: moment(dob).format("YYYY-MM-DD"),
    nominee_applicable: "100",
    nominee_guardian_name: guardianName,
    nominee_relation: relation,
    user_id: user_id,
  };

  // const addnomineedetails = async () => {
  //   try {
  //     var form_data = addnominee_req;
  //     if (form_data.nominee_guardian_name.trim().length == 0) {
  //       form_data.nominee_guardian_name = undefined;
  //     }
  //     var data = commonEncode.encrypt(JSON.stringify(form_data));
  //     var config = {
  //       method: "post",
  //       url: constClass.DMF_ADDNOMINEE_API_URL,
  //       data: data,
  //     };

  //     var response = await axios(config);
  //     var response_data = commonEncode.decrypt(response.data);
  //     let responseobj = JSON.parse(response_data);
  //     let error_code = responseobj.error_code;
  //     var msg = responseobj.message;

  //     if (error_code == "100") {
  //       dispatch({
  //         type: "RENDER_TOAST",
  //         payload: { message: "Nominee added successfully!", type: "success" },
  //       });
  //       nomineelist(true);
  //     } else {
  //       if (msg == "Please provide valid nominee_first_name") {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please provide valid nominee name",
  //             type: "error",
  //           },
  //         });
  //       }
  //       if (msg == "Please provide valid nominee_guardian_name!") {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please provide valid nominee guardian name",
  //             type: "error",
  //           },
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     dispatch({
  //       type: "RENDER_TOAST",
  //       payload: { message: "Something went Wrong...", type: "error" },
  //     });
  //   }
  // };

  // const updatenomineedetails = async () => {
  //   try {
  //     var form_data = {};
  //     form_data.user_id = user_id;
  //     form_data.nominee_id = "" + allNominee[0].nominee_id;
  //     form_data.nominee_first_name = "" + editThis.nominee_first_name;
  //     form_data.nominee_applicable = "100 ";
  //     form_data.nominee_relation = "" + editThis.nominee_relation;
  //     form_data.nominee_guardian_name = "" + editThis.nominee_guardian_name;
  //     form_data.nominee_dob =
  //       "" + moment(editThis.nominee_dob).format("YYYY-MM-DD");

  //     if (form_data.nominee_guardian_name.trim().length == 0) {
  //       form_data.nominee_guardian_name = undefined;
  //     }
  //     var data = commonEncode.encrypt(JSON.stringify(form_data));
  //     var config = {
  //       method: "post",
  //       url: constClass.DMF_UPDATENOMINEE_API_URL,
  //       data: data,
  //     };
  //     var res = await axios(config);
  //     var response = commonEncode.decrypt(res.data);
  //     let responseobj = JSON.parse(response);
  //     let error_code = responseobj.error_code;

  //     if (error_code == "102") {
  //       if (
  //         responseobj.message ==
  //         "Following field(s) are passed in request with empty values: 'nominee_first_name'!"
  //       ) {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: { message: "Please enter nominee name ", type: "error" },
  //         });
  //         return;
  //       } else if (
  //         responseobj.message ==
  //         "Following fields are mandatory for minor nominees: nominee_guardian_name!"
  //       ) {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please enter nominee guardian name ",
  //             type: "error",
  //           },
  //         });
  //         return;
  //       } else if (
  //         responseobj.message == "Please Provide Valid nominee_first_name"
  //       ) {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please provide valid nominee name",
  //             type: "error",
  //           },
  //         });
  //         return;
  //       } else
  //         responseobj.message == "Please provide valid nominee_guardian_name!";
  //       {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please enter valid nominee guardian name ",
  //             type: "error",
  //           },
  //         });
  //         return;
  //       }
  //     } else if (error_code == "100") {
  //       dispatch({
  //         type: "RENDER_TOAST",
  //         payload: {
  //           message: "Nominee updated successfully!",
  //           type: "success",
  //         },
  //       });
  //       editData(false);
  //       nomineelist();
  //     }
  //   } catch (e) {
  //     dispatch({
  //       type: "RENDER_TOAST",
  //       payload: { message: "Something went Wrong..", type: "error" },
  //     });
  //   }
  // };

  const saveNominee = async () => {
    // if (validateForm() == false) return;
    try {
      const payload = {
        method: 'post',
        url: constClass.DMF_ADDNOMINEE_API_URL,
        data: {
          nominee_first_name: fullname,
          nominee_dob: dob,
          nominee_applicable: "100",
          nominee_relation: relation,
          user_id: getUserId(),
          nominee_guardian_name: guardianName == '' ? undefined : guardianName,
          nominee_id: nomineeid ? nomineeid : undefined,
          is_authenticated:"1"                
        }
      }
      if(payload.data.nominee_id) {
        payload.url = constClass.DMF_UPDATENOMINEE_API_URL
      }
      const r = await fetchEncryptData(payload);
      if(r.error_code === '100') {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: r.message, type: "success" },
        });
        setShowdata(false);
        props.onNext(navDynamicNext);
      } else {
        dispatch({
          type: "RENDER_TOAST",
          payload: { message: r.message, type: "error" },
        });
      }
    } catch(e) {
      console.log('e------>', e);
    }
    
  };

  useEffect(() => {
    if (allNominee.length < 1) {
      setAddMore(true);
    } else {
      setAddMore(false);
    }
  }, [allNominee]);

  function conditionalnext() {
    if (allNominee.length > 0) {
      props.onNext(navDynamicNext);
    } else {
      setAddMore(true);
      if (allNominee.length == 1) {
        props.onNext(navDynamicNext);
      } else {
        addNewRecord();
      }
    }
  }

  const fetchSms = async () => {
    var sms_api_id = "fintoo_otp";
    var otp = randomOTP.current;
    // setGeneratedSmsOTP(otp);
    // var msg = "Greetings from Fintoo! Your OTP verification code is " + otp;
    // var whatsapptext =
    //   "Greetings from Fintoo! Your OTP verification code is : " + otp;

    var urlsms = {
      mobile: userDetails.mobile,
      otp: otp,
      key: "add_nominee",
      is_direct: constClass.IS_DIRECT,
    };
    var data2 = commonEncode.encrypt(JSON.stringify(urlsms));
    var config1 = {
      method: "post",
      url: constClass.DMF_SENDSMS_API_URL,
      data: data2,
    };

    var res = await axios(config1);
    var response = commonEncode.decrypt(res.data);
  };
  

  const fetchMail = async () => {
    var otp = randomOTP.current;
    setGeneratedEmailOTP(otp);
    console.log(otp,"otppp")
    var urlmail = {
      userdata: {
        to: email1,
      },
      subject: "Fintoo - Verification for your new account",
      template: "otp_message_template.html",
      contextvar: { otp: otp },
    };

    var data = commonEncode.encrypt(JSON.stringify(urlmail));
    var config = {
      method: "post",
      url: constClass.DMF_SENDMAIL_API_URL,
      data: data,
    };

    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    var rep_msg = response.data;
  };


  return (
    <>
      <ToastContainer limit={1} />
      <Row className="reverse">
        <div className="ProfileImg col-12 col-md-6">
          <div>
            <img src={Profile_1} alt="" />
          </div>
        </div>
        <div className=" col-12 col-md-6 RightPanel">
          <div className="rhl-inner">
            {showBack == true && (
              <FintooProfileBack
                title="Add Nominee"
                onClick={() => props.onPrevious(navDynamicPrev)}
              />
            )}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Label className="LabelName pt-4" htmlFor="inputText">
                Please add your nominee details.
              </Form.Label>

              <div style={{}}>
                {allNominee.map((v, i) => (
                  <div key={`ff-${JSON.stringify(v)}`}>
                    {editNumber == i ? (
                      <></>
                    ) : (
                      <div className="d-flex mylist-items-1 my-3">
                        {/* <div className="txi-item">
                        <div className="my-badge">{i + 1}</div>
                      </div> */}
                        <table className="flex-grow-1 ftc-tbl">
                          <tr>
                            <td>
                              <p className="tbl-head">Full Name</p>

                              <p
                                className="tbl-txt"
                                style={{ textTransform: "capitalize" }}
                              >
                                {v.nominee_first_name}
                              </p>
                            </td>
                            <td>
                              <div className="txi-item in-tr-item">
                                <p className="tbl-options">
                                  <span>
                                    <FaEdit
                                      onClick={() => {
                                        editData(v, i);
                                        setAddMore(false);
                                      }}
                                    />
                                  </span>
                                </p>
                              </div>
                              <p className="tbl-head">Allocation</p>
                              <p className="tbl-txt">100 %</p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="tbl-head">Relation</p>
                              <p
                                className="tbl-txt"
                                style={{ textTransform: "capitalize" }}
                              >
                                {v.nominee_relation.replace("siblings","Sibling")}
                              </p>
                            </td>
                            <td>
                              <p className="tbl-head">Date of birth </p>
                              <p className="tbl-txt">
                                {moment(v.nominee_dob).format("DD-MM-YYYY")}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    )}
                    {editNumber == i && (
                      <div className="mylist-items-2 my-3 position-relative">
                        <div className="btn-cross">
                          <FaMinusCircle
                            className=" fintoo-blue"
                            onClick={() => cancelForm()}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 profile-space-1">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Nominee Full Name 1
                            </Form.Label>
                            <Form.Control
                              required
                              autoComplete
                              placeholder="Name"
                              type="text"
                              maxlength="40"
                              style={{
                                height: "2.5rem",
                                borderRadius: "10px",
                              }}
                              className="NomineeName shadow-none"
                              id="inputText"
                              aria-describedby="passwordHelpBlock"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  /^[a-z A-Z]+$/.test(e.target.value)
                                ) {
                                  setEditThis({
                                    ...editThis,
                                    nominee_first_name: e.target.value,
                                  });
                                }
                              }}
                              value={editThis.nominee_first_name}
                            />
                            {"fullname" in error && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {error.fullname}
                              </Form.Control.Feedback>
                            )}
                          </div>
                          <div className="col-12 col-md-6  profile-space-1">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Allocation
                            </Form.Label>
                            <Form.Control
                              required
                              className="shadow-none"
                              style={{
                                borderRadius: "10px",
                                height: "2.5rem",
                                outline: "none",
                              }}
                              classname=""
                              placeholder="%"
                              value="100"
                              onChange={(e) => setPercentage(e.target.value)}
                            ></Form.Control>
                            {"percentage" in error && (
                              <div className="invalid-feedback d-block">
                                {error.percentage}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-md-6 profile-space-1">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Relation
                            </Form.Label>
                            <Form.Select
                              required
                              aria-label="Default select example"
                              style={{
                                borderRadius: "10px",
                                height: "2.5rem",
                                outline: "none",
                              }}
                              className="NomineeName shadow-none"
                              onChange={(e) =>
                                setEditThis({
                                  ...editThis,
                                  nominee_relation: e.target.value,
                                })
                              }
                              value={editThis.nominee_relation}
                            >
                              {/* <option value = ""> Select </option> */}
                              <option value="father">Father</option>
                              <option value="mother">Mother</option>
                              <option value="son">Son</option>
                              <option value="husband">Husband</option>
                              <option value="wife">Wife</option>
                              <option value="daughter">Daughter</option>
                              <option value="siblings">Sibling</option>
                            </Form.Select>
                            {"relation" in error && (
                              <div className="invalid-feedback d-block">
                                {error.relation}
                              </div>
                            )}
                          </div>
                          <div className=" col-12 col-md-6 profile-space-1">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Date of birth
                            </Form.Label>
                            <FormGroup controlId="date" bsSize="large">
                              <div
                                className={`nominee-calendar dob8 ${
                                  dob ? "m_selected" : "m_empty"
                                }`}
                              >
                                <FintooDatePicker
                                  maxDate={new Date()}
                                  monthPlaceholder="MM"
                                  yearPlaceholder="YYYY"
                                  dayPlaceholder="DD"
                                  className={`my-dob-calendar`}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onChange={(date) => {
                                    setEditThis({
                                      ...editThis,
                                      nominee_dob: moment(date),
                                    });
                                  }}
                                  selected={new Date(editThis.nominee_dob)}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                            </FormGroup>
                            {"dob" in error && (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {error.dob}
                              </Form.Control.Feedback>
                            )}
                          </div>

                          {moment().diff(
                            moment(editThis.nominee_dob),
                            "years"
                          ) < 18 && (
                            <div className="col-12 profile-space-1 col-md-6">
                              <Form.Label
                                className="LabelName"
                                htmlFor="inputText"
                              >
                                Parent/Guardian name
                              </Form.Label>
                              <Form.Control
                                placeholder="Name"
                                className="shadow-none"
                                maxlength="40"
                                type="text"
                                style={{
                                  height: "2.5rem",
                                  borderRadius: "10px",
                                }}
                                id="inputText"
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    /^[a-z A-Z]+$/.test(e.target.value)
                                  ) {
                                    setEditThis({
                                      ...editThis,
                                      nominee_guardian_name: e.target.value,
                                    });
                                  }
                                }}
                                value={editThis.nominee_guardian_name}
                                required
                              />
                              <Form.Control.Feedback type="invalid">
                                Parent/Guardian name is required.
                              </Form.Control.Feedback>
                            </div>
                          )}
                          <div className="col-12 profile-space-1 col-md-6">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              &nbsp;
                            </Form.Label>
                            <div>
                              <FintooButton
                                type="button"
                                className={`d-block me-0 ms-auto`}
                                onClick={() => {
                                  // updatenomineedetails();
                                }}
                                title={"Save"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {allNominee.length < 1 && (
                <div
                  className="profile-addmore noselect"
                  onClick={() => {
                    setAddMore(true);
                    setEditNumber(null);
                    resetForm();
                  }}
                ></div>
              )}
              <div>
                <div className="mylist-items-3 my-3 position-relative">
                  {/* <div className="btn-cross"><FaMinusCircle className=" fintoo-blue" onClick={() => cancelForm()} /></div> */}
                  <div className="row">
                    <div className="col-12 col-md-6 profile-space-1 mainformhere">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Nominee Full Name
                      </Form.Label>
                      <Form.Control
                        autoComplete
                        placeholder="Name"
                        type="text"
                        style={{
                          height: "2.5rem",
                          borderRadius: "10px",
                        }}
                        className="NomineeName shadow-none"
                        id="inputText"
                        aria-describedby="passwordHelpBlock"
                        maxlength="40"
                        value={fullname}
                        onChange={(e) => {
                          if (
                            e.target.value === "" ||
                            /^[a-z A-Z]+$/.test(e.target.value)
                          ) {
                            setFullname(e.target.value);
                          }
                        }}
                      />
                      {"fullname" in error && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {error.fullname}
                        </Form.Control.Feedback>
                      )}
                    </div>
                    <div className="col-12 col-md-6  profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Percentage
                      </Form.Label>
                      <Form.Control
                        required
                        className="shadow-none"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        classname=""
                        placeholder=""
                        value="100 %"
                        onChange={(e) => setPercentage(e.target.value)}
                      ></Form.Control>
                      {"percentage" in error && (
                        <div className="invalid-feedback d-block">
                          {error.percentage}
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-6 profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Relation
                      </Form.Label>
                      <Form.Select
                        required
                        aria-label="Default select example"
                        style={{
                          borderRadius: "10px",
                          height: "2.5rem",
                          outline: "none",
                        }}
                        className="NomineeName shadow-none"
                        onChange={(e) => setRelation(e.target.value)}
                        value={relation}
                      >
                        <option value="">Select</option>
                        <option value="mother">Mother</option>
                        <option value="father">Father</option>
                        <option value="son">Son</option>
                        <option value="husband">Husband</option>
                        <option value="wife">Wife</option>
                        <option value="daughter">Daughter</option>
                        <option value="siblings">Sibling</option>
                      </Form.Select>
                      {"relation" in error && (
                        <div className="invalid-feedback d-block">
                          {error.relation}
                        </div>
                      )}
                    </div>
                    <div className=" col-12 col-md-6 profile-space-1">
                      <Form.Label className="LabelName" htmlFor="inputText">
                        Date of Birth
                      </Form.Label>
                      <FormGroup controlId="date" bsSize="large">
                        <div
                          className={`dob8 nominee-calendar ${
                            dob ? "m_selected" : "m_empty"
                          }`}
                        >
                          <FintooDatePicker
                            showMonthDropdown
                            showYearDropdown
                            maxDate={new Date()}
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            dayPlaceholder="DD"
                            className={`my-dob-calendar`}
                            onChange={(v) => setDob(moment(v).format('Y-MM-D'))}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            selected={dob ? new Date(dob) : null}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </FormGroup>
                      {"dob" in error && (
                        <div className="invalid-feedback d-block">
                          {error.dob}
                        </div>
                      )}
                    </div>
                    <div className="col-12 profile-space-1 col-md-6">
                      {moment().diff(moment(dob), "years") < 18 && (
                        <>
                          <Form.Label className="LabelName" htmlFor="inputText">
                            Parent/Guardian name
                          </Form.Label>
                          <Form.Control
                            placeholder="Name"
                            className="shadow-none"
                            required
                            type="text"
                            maxlength="40"
                            style={{
                              height: "2.5rem",
                              borderRadius: "10px",
                            }}
                            id="inputText"
                            aria-describedby="passwordHelpBlock"
                            value={guardianName}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                /^[a-z A-Z]+$/.test(e.target.value)
                              ) {
                                setGuardianName(e.target.value);
                              }
                            }}
                          />
                        </>
                      )}
                      {"guardianName" in error && (
                        <div className="invalid-feedback d-block">
                          {error.guardianName}
                        </div>
                      )}
                    </div>

                    
                  </div>
                </div>
              </div>
            </Form>
            <div>
              {theArray.map((entry) => (
                <div>
                  <span onClick={removeNominee} style={{ cursor: "pointer" }}>
                    {" "}
                    <FaMinusCircle />{" "}
                  </span>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row className="Nomineeadd" style={{ width: "78%" }}>
                      <Col className=" p-2">
                        <div className="Nominee_Option">
                          <div>
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Nominee Full Name 3
                            </Form.Label>
                            <Form.Control
                              required
                              placeholder="Name"
                              type="text"
                              style={{
                                height: "2.5rem",
                                borderRadius: "10px",
                              }}
                              className="NomineeName shadow-none"
                              id="inputText"
                              aria-describedby="passwordHelpBlock"
                            />
                            <Form.Control.Feedback type="invalid">
                              Full Name Required
                            </Form.Control.Feedback>
                          </div>
                          <div className="Percent">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Add Percentage
                            </Form.Label>
                            <Form.Control
                              required
                              className="shadow-none"
                              style={{
                                borderRadius: "10px",
                                height: "2.5rem",
                                outline: "none",
                              }}
                              classname=""
                              placeholder="%"
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field Required
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        <div className="Nominee_Option">
                          <div className="">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Relationship with Nominee
                            </Form.Label>
                            <Form.Select
                              required
                              aria-label="Default select example"
                              style={{
                                borderRadius: "10px",
                                height: "2.5rem",
                                outline: "none",
                              }}
                              className="NomineeName shadow-none"
                            >
                              <option>Select</option>
                              <option value="1">Father</option>
                              <option value="2">Mother</option>
                              <option value="3">Son</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              This field Required
                            </Form.Control.Feedback>
                          </div>
                          <div className="Percent">
                            <Form.Label
                              className="LabelName"
                              htmlFor="inputText"
                            >
                              Nominee Date of Birth
                            </Form.Label>
                            <Form.Control
                              className="shadow-none"
                              required
                              style={{
                                borderRadius: "10px",
                                height: "2.5rem",
                                outline: "none",
                              }}
                              type="date"
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Date of Birth Required
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div
                          className="Nominee_Identity "
                          style={{
                            marginTop: "1rem",
                          }}
                        >
                          <p>{moment().diff(moment(dob), "years")}</p>
                          <Form.Label className="LabelName" htmlFor="inputText">
                            Guardian Name if Minor
                          </Form.Label>
                          <Form.Control
                            placeholder="Name"
                            className="shadow-none"
                            required
                            type="text"
                            style={{
                              height: "2.5rem",
                              borderRadius: "10px",
                            }}
                            id="inputText"
                            aria-describedby="passwordHelpBlock"
                          />
                          <Form.Control.Feedback type="invalid">
                            Full Name Required
                          </Form.Control.Feedback>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              ))}
            </div>
            <div className="pt-4 fintoo-top-border mt-4">
            <div className={`mb-3,mx-2 ${
                        moment().diff(moment(dob), "years") <
                        18
                          ? ""
                          : "d-none"
                      }`} style={{fontFamily: "Red Hat Text"}}>
                <p className="mt-4">Since your nominee is a minor, please enter his/her guardian details. The guardian should not be the same as the Fintoo account holder - that’s you!</p><strong>Note:</strong> Account holder(s) cannot be a Guardian.</div>
              <FintooButton
                className={`d-block me-0 ms-auto`}
                // {validateForm() == false ?}
                onClick={() => {
                  if(validateForm() == false) {return}
                  else{randomOTP.current =
                    Math.floor(Math.random() * 90000) + 10000;
                  startTimer();
                  fetchMail();
                  fetchSms();
                  setShowdata(true);}
                  
                }}
                title={"Next"}
              />
            </div>
            <p>&nbsp;</p>
          </div>
        </div>
        <Modal
        backdrop="static"
        className="NomineeModal white-modal"
        centered
        show={showdata}
        onHide={() => handleClosedata()}
      >
        <div className="d-flex justify-center p-3">
          {/* <div>
            {" "}
            <FintooBackButton onClick={() => handleClosedata()} />
          </div> */}
          <div
            className="DeleteBank text-center pb-3 w-100"
            style={{
              borderBottom: "1px solid #eeee",
            }}
          >
            {/* <div
              style={{
                fontWeight: "500",
              }}
            >
              {" "}
              Validate Nominee
            </div> */}
            <div
              className="pt-2"
              style={{
                fontWeight: "600",
              }}
            >
              Enter OTP
            </div>
            
          </div>
        </div>
        <div>
          <div>
          <div
          className="text-center"
              style={{
                fontWeight: "500",
                alignContent: "center"
              }}
            >
              {"          "}
              Validate Nominee
            </div>
            <div className="p-3">
              <div className="modal-whitepopup-box-item  border-top-0 text-center">
                <p>Sent to</p>
                <p>{email1}</p>
                <p>{mobile1}</p>
              </div>
              <div className="d-flex justify-center align-items-center">
                <center>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    style={{
                      width: "8%",
                    }}
                    autoFocus
                    className="rounded rounded-otp"
                    OTPLength={5}
                    otpType="number"
                    disabled={false}
                  />
                </center>
              </div>

              <div
                style={{
                  borderBottom: "1px solid #eeee",
                }}
                className="text-center p-3 grey-color"
              >
                {count == 0 && (
                  <p
                    className="pointer blue-color"
                    onClick={() => {
                      randomOTP.current =
                        Math.floor(Math.random() * 90000) + 10000;
                      startTimer();
                      fetchMail();
                      fetchSms();
                    }}
                  >
                    Resend OTP
                  </p>
                )}

                {count > 0 && (
                  <p>
                    Resend in &nbsp;
                    <strong>
                      {moment().startOf("day").seconds(count).format("mm:ss")}
                    </strong>
                  </p>
                )}
              </div>
            </div>
            <div
              className="mt-3"
              style={{background: "#52afde",
                textAlign: "center",
                fontWeight: "bold",
                color: "#fff",
                borderBottomLeftRadius: "1rem",
                borderBottomRightRadius: "1rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                cursor: "pointer"}}
              onClick={() => {
                if (GeneratedSmsOTP != OTP && generatedemailotp != OTP) {
                  dispatch({
                    type: "RENDER_TOAST",
                    payload: { message: "INVALID OTP", type: "error" },
                  });
                  return;
                }
                saveNominee();
                // updatenomineedetails();
              }}
            >
              Continue
            </div>
          </div>
        </div>
      </Modal>
      </Row>
    </>
  );
}

export default NomineeDetails;

import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import HDFC from "../../../components/Assets/Dashboard/hdfc.png";
import Female from "../../../components/Assets/Dashboard/female.png";
import Profile from "../../../components/Assets/Dashboard/profile.png";

import Delete from "../../../components/Assets/Dashboard/delete_Gray.png";
import { RiDeleteBinLine, RiEditBoxLine } from "react-icons/ri";
import FintooBackButton from "../../../components/HTML/FintooBackButton";
import { Link } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { ToastContainer, toast } from "react-toastify";
import {
  CheckSession,
  apiCall,
  successAlert,
  errorAlert,
  getUserId,
  getPublicMediaURL,
} from "../../../common_utilities";

import moment from "moment";
import axios from "axios";
import commonEncode from "../../../commonEncode";
import SimpleReactValidator from "simple-react-validator";
import FintooDatePicker from "../../../components/HTML/FintooDatePicker";

import DeleteBank from "../../../components/Assets/Dashboard/delete_bank.png";
import { MdSmartScreen } from "react-icons/md";
import { useDispatch ,useSelector} from "react-redux";
import * as constClass from "../../../constants";

function NomineeList(props) {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const randomOTP = useRef(Math.floor(Math.random() * 90000) + 10000);

  var userid = getUserId();

  const [removed, setRemoved] = useState(false);

  const [dob, setDob] = useState(null);
  const [eighteenYearsAgo, setEighteenYearsAgo] = useState(new Date());
  const [editNominee, setEditNominee] = React.useState(false);
  const EditnomineeData = () => setEditNominee(true);
  const [show, setShow] = useState(false);
  const [showdata, setShowdata] = useState(false);
  const handleShow = () => setEditNominee(false);
  const handleClose = () => setShow(false);
  const handleClosedata = () => setShowdata(false);
  const [goingToDelete, setGoingToDelete] = useState(null);
  const [editThis, setEditThis] = useState(null);
  const [validated, setValidated] = useState(false);
  const [uOTP, updatesetOTP] = useState("");
  const simpleValidator = useRef(new SimpleReactValidator()); 
  const timer = useRef({ obj: null, counter: 120, default: 120 });
  const [count, setCount] = useState(120);

  const [nominee_id, setnominee_id] = useState("");
  const [name, setName] = useState("");
  const [percentageallocation, setpercentageallocation] = useState("");
  const [nomineerelationship, setnomineerelationship] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [gardianname, setgardianname] = useState("");
  const [user_mobile, setusermobile] = useState("");
  const [useremail, setuseremail] = useState("");
  const [GeneratedSmsOTP, setGeneratedSmsOTP] = useState("");
  const [generatedemailotp, setGeneratedEmailOTP] = useState("");
  const [, forceUpdate] = useState();

  const dispatch = useDispatch();
  
  const nominee_back = useSelector((state)=> state.nominee_back);

  useEffect(()=>{
    setEditNominee(false);
  },[nominee_back])

  useEffect(() => {
    onLoadInIt();
  }, [user_mobile, useremail]);

  const onLoadInIt = async () => {
    try {
      var form_data_user = { user_id: userid };
      var data = commonEncode.encrypt(JSON.stringify(form_data_user));

      var config_list = {
        method: "post",
        url: constClass.DMF_GETUSERDETAILS_API_URL,
        data: data,
      };

      var res = await axios(config_list);
      var response = commonEncode.decrypt(res.data);
      let responseobj = JSON.parse(response);
      let user_data = responseobj.data;

      setusermobile(user_data.mobile);
      setuseremail(user_data.email);
    } catch (e) {
      dispatch({
        type: "RENDER_TOAST",
        payload: { message: "Something went Wrong...", type: "error" },
      });
      setError(true);
    }
  };

  const fetchSms = async () => {
    var sms_api_id = "fintoo_otp";
    var otp = randomOTP.current;

    setGeneratedSmsOTP(otp);
    var msg = "Greetings from Fintoo! Your OTP verification code is " + otp;
    var whatsapptext =
      "Greetings from Fintoo! Your OTP verification code is : " + otp;

    var urlsms = {
      mobile: user_mobile,
      otp: otp,
      key:"add_nominee",
      is_direct : constClass.IS_DIRECT,
      // sms_api_id: sms_api_id,
      // whatsapptext: whatsapptext,

    };
    var data2 = commonEncode.encrypt(JSON.stringify(urlsms));
    var config1 = {
      method: "post",
      url: constClass.DMF_SENDSMS_API_URL,
      data: data2,
    };

    var res = await axios(config1);
    var response = commonEncode.decrypt(res.data);
  };

  const fetchMail = async () => {
    var otp = randomOTP.current;
    setGeneratedEmailOTP(otp);

    var urlmail = {
      userdata: {
        to: useremail,
      },
      subject: "Fintoo - Verification for your new account",
      template: "otp_message_template.html",
      contextvar: { otp: otp },
    };

    var data = commonEncode.encrypt(JSON.stringify(urlmail));
    var config = {
      method: "post",
      url: constClass.DMF_SENDMAIL_API_URL,
      data: data,
    };

    var res = await axios(config);
    var response = commonEncode.decrypt(res.data);
    var rep_msg = response.data;
  };

  const startTimer = () => {
    setOTP("");
    updatesetOTP("");
    clearInterval(timer.current.obj);
    timer.current.counter = timer.current.default;
    setCount(timer.current.counter);
    timer.current.obj = setInterval(() => {
      if (timer.current.counter == 0) {
        clearInterval(timer.current.obj);
        timer.current.counter = timer.current.default;
        return;
      }
      timer.current.counter = timer.current.counter - 1;
      setCount(timer.current.counter);
    }, 1000);
  };

  const resendTimer = () => {
    timer.current.obj = setInterval(() => {}, 1000);
  };
  const [OTP, setOTP] = useState("");
  var id = String(props.NomineeData.nominee_id);
  var addnominee_req = {
    nominee_first_name: name,
    nominee_dob: dateofbirth,
    nominee_applicable: percentageallocation,
    nominee_guardian_name: gardianname,
    nominee_relation: nomineerelationship,
    user_id: userid,
    nominee_id: id,
  };

  function refreshPage() {
    window.location.reload(true);
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      if (form_data.nominee_first_name.length > 1) editnomineeData();
    }
    setValidated(true);
  };

  // const editnomineeData = () => {
  //   setName(name);
  //   setpercentageallocation(percentageallocation);
  //   setnomineerelationship(nomineerelationship);
  //   setdateofbirth(dateofbirth);
  //   setgardianname(gardianname);
  //   setnominee_id(nominee_id);
  // };

  // const updatenomineedetails = async () => {
  //   try {
  //     var form_data = {};
  //     form_data.user_id = userid;
  //     form_data.nominee_id = id;
  //     form_data.nominee_first_name = editThis.nominee_first_name;
  //     form_data.nominee_applicable = editThis.nominee_applicable;
  //     form_data.nominee_relation = editThis.nominee_relation;
  //     form_data.nominee_guardian_name = editThis.nominee_guardian_name;
  //     form_data.nominee_dob = moment(editThis.nominee_dob).format("YYYY-MM-DD");

  //     Object.keys(form_data).forEach((v) => {
  //       if (typeof form_data[v] == "number") {
  //         form_data[v] = form_data[v] + "";
  //       }
  //     });

  //     form_data.nominee_guardian_name = form_data.nominee_guardian_name;
  //     if (
  //       form_data.nominee_guardian_name == "" ||
  //       form_data.nominee_guardian_name == null
  //     ) {
  //       form_data.nominee_guardian_name === "";
  //     }

  //     var data = commonEncode.encrypt(JSON.stringify(form_data));
  //     var config = {
  //       method: "post",
  //       url: constClass.DMF_UPDATENOMINEE_API_URL,
  //       data: data,
  //     };

  //     var res = await axios(config);
  //     var response = commonEncode.decrypt(res.data);

  //     let responseobj = JSON.parse(response);
  //     let error_code = responseobj.error_code;
  //     var msg = responseobj.message;

  //     if (error_code == "102") {
  //       setShowdata(false);
  //       if (msg == "Please provide valid nominee_first_name") {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please provide valid nominee name",
  //             type: "error",
  //           },
  //         });
  //         return;
  //       }
  //       if (msg == "Please provide valid nominee_guardian_name!") {
  //         dispatch({
  //           type: "RENDER_TOAST",
  //           payload: {
  //             message: "Please provide valid nominee guardian name",
  //             type: "error",
  //           },
  //         });
  //       }
  //     } else if (error_code == "100") {
  //       dispatch({
  //         type: "RENDER_TOAST",
  //         payload: {
  //           message: "Nominee updated successfully!",
  //           type: "success",
  //         },
  //       });
  //     }

  //     setShowdata(false);
  //     setEditNominee(false);
  //     props.refreshPage();
  //   } catch (e) {
  //     dispatch({
  //       type: "RENDER_TOAST",
  //       payload: { message: "Something went wrong...", type: "error" },
  //     });
  //   }
  // };

  var mobile = user_mobile.slice(7);
  const handleChange = (v) => {
    setDob(v);
  };
  return (
    <>
      {editNominee ? (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} lg={8}>
              <Row className="Bank-details nomineeData">
                <Col xs={12} lg={12} className="b-layout">
                  <div>
                    <FintooBackButton onClick={() => setEditNominee(false)} />
                  </div>
                  <div className="bank-name nomiee ms-5">
                    <div className="sm:w-100">
                      <div className="memeber-logo">
                        <img
                         src={getPublicMediaURL("static/media/DMF/uni-user-ic.png")}
                        />
                      </div>
                    </div>

                    <div className="bank-data DesktopNomineeName">
                      <div className="bank-label">
                        <p>Nominee Name </p>
                      </div>
                      <div className="bank-info Nominee-name">
                        <div>
                          <input
                            aria-label="Default select example"
                            className="shadow-none w-50 form-control"
                            placeholder="Name"
                            maxlength="40"
                            onChange={(e) => {
                              setEditThis({
                                ...editThis,
                                nominee_first_name: e.target.value,
                              });
                              simpleValidator.current.showMessageFor(
                                "nomineeNameRequired"
                              );
                            }}
                            value={editThis.nominee_first_name}
                            onKeyPress={(event) => {
                              if (!/[A-Za-z ]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {simpleValidator.current.message(
                            "nomineeNameRequired",
                            editThis.nominee_first_name,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="lg:mt-2"
                    >
                      <div className="Edit-nominee">
                        <button
                          onClick={() => {
                            var formValid = simpleValidator.current.allValid();
                            simpleValidator.current.showMessages();
                            forceUpdate(1);

                            if (formValid === true) {
                              randomOTP.current =
                                Math.floor(Math.random() * 90000) + 10000;
                              setShowdata(true);
                              fetchMail();
                              fetchSms();
                              startTimer();
                            }
                          }}
                          disabled={
                            editThis.nominee_first_name.length < 2 ||
                            (editThis.nominee_guardian_name &&
                              editThis.nominee_guardian_name.length < 2)
                          }
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
                <span
                  style={{
                    borderBottom: "1px solid #eeee",
                    paddingTop: "1em",
                    paddingLeft: "0rem !important",
                    paddingRight: "0rem !important",
                  }}
                ></span>
                <div className="b-subdetails col-12">
                  <Row>
                    <Col
                      xs={12}
                      lg={3}
                      className="b-layout nomineeData mobileNomineeName"
                    >
                      <div className="bank-data">
                        <div className="bank-label">
                          <p>Nominee Name</p>
                        </div>
                        <div className="bank-info Nominee-name">
                          <div>
                            <Form.Control
                              aria-label="Default select example"
                              className="shadow-none w-100"
                              placeholder="Name"
                            ></Form.Control>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} lg={3} className="b-layout">
                      <div className="bank-name">
                        <div>
                          <div className="bank-log"></div>
                        </div>
                        <div className="bank-data">
                          <div className="bank-label nomiee-label">
                            <p>Percentage Allocation</p>
                          </div>
                          <div className="bank-info">
                            <Form.Control
                              aria-label="Default select example"
                              className="shadow-none "
                              value={editThis.nominee_applicable}
                              required
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Percentage Allocation
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} lg={3} className="b-layout ">
                      <div className="bank-label nomiee-label bank-data">
                        <p>Nominee Relationship</p>
                      </div>
                      <div className="bank-info">
                        <Form.Select
                          aria-label="Default select example"
                          className="shadow-none "
                          onChange={(e) =>
                            setEditThis({
                              ...editThis,
                              nominee_relation: e.target.value,
                            })
                          }
                          value={editThis.nominee_relation}
                        >
                          {/* <option value = "">Select </option> */}
                          <option value="mother">Mother</option>
                          <option value="father">Father</option>
                          <option value="son">Son</option>
                          <option value="husband">Husband</option>
                          <option value="wife">Wife</option>
                          <option value="daughter">Daughter</option>
                          <option value="siblings">Sibling</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          select NomineeRelation
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col xs={12} lg={3} className="b-layout ">
                      <div className="bank-label nomiee-label bank-data">
                        <p>Date of Birth</p>
                      </div>
                      <div className="bank-info">
                        <FintooDatePicker
                          value={moment(editThis.nominee_dob).toDate()}
                          onChange={(v) => {
                            setEditThis({ ...editThis, nominee_dob: v });
                          }}
                          maxDate={moment().toDate()}
                        />
                        <Form.Control.Feedback type="invalid">
                          Date of Birth
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      lg={3}
                      className={`b-layout ${
                        moment().diff(moment(editThis.nominee_dob), "years") <
                        18
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <div className="bank-label nomiee-label bank-data">
                        <p>Parent/Guardian full name</p>
                      </div>
                      <div className="bank-info">
                        <input
                          aria-label="Default select example"
                          className="shadow-none form-control"
                          placeholder="Name"
                          maxlength="40"
                          onKeyPress={(event) => {
                            if (!/[A-Za-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            setEditThis({
                              ...editThis,
                              nominee_guardian_name: e.target.value,
                            })
                          }
                          value={editThis.nominee_guardian_name}
                        />
                        {moment().diff(moment(editThis.nominee_dob), "years") <
                          18 &&
                          simpleValidator.current.message(
                            "parent/Guardian",
                            editThis.nominee_guardian_name,
                            "required"
                          )}
                        <Form.Control.Feedback type="invalid">
                          Guardian Name
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
              <div className={`mt-3, mx-2 ${
                        moment().diff(moment(editThis.nominee_dob), "years") <
                        18
                          ? ""
                          : "d-none"
                      }`} style={{fontFamily: "Red Hat Text"}}>
                <p className="mt-4">Since your nominee is a minor, please enter his/her guardian details. The guardian should not be the same as the Fintoo account holder - that’s you!</p><strong>Note:</strong> Account holder(s) cannot be a Guardian.</div>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          <div className="Bank-P-de nomineeData desktopView">
            <Row>
              <Col xs={12} lg={8}>
                <Row className="Bank-details">
                  <Col xs={12} lg={12} className="b-layout">
                    <div className="bank-name nomiee">
                      <div>
                        <div className="memeber-logo">
                          <img
                          src={getPublicMediaURL("static/media/DMF/uni-user-ic.png")}
                          />
                        </div>
                      </div>
                      <div
                        className="bank-data"
                        style={{
                          marginLeft: ".6em",
                        }}
                      >
                        <div className="bank-label">
                          <p>Nominee Name</p>
                        </div>
                        <div
                          className="bank-info Nominee-name"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div>
                            {props.NomineeData.nominee_first_name}{" "}
                            {props.NomineeData.nominee_middle_name}{" "}
                            {props.NomineeData.nominee_last_name}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="lg:mt-2"
                      >
                        {/* <div className="Edit-nominee">
                          <button
                            onClick={() => {
                              setEditThis({ ...props.NomineeData });
                              setEditNominee(true);
                            }}
                          >
                            Edit
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </Col>
                  <span
                    style={{
                      borderBottom: "1px solid #eeee",
                      paddingTop: "1em",
                      paddingLeft: "0rem !important",
                      paddingRight: "0rem !important",
                    }}
                  ></span>
                  <div className="b-subdetails col-12">
                    <Row>
                      <Col xs={6} lg={4} className="b-layout">
                        <div className="bank-name">
                          <div>
                            <div className="bank-log"></div>
                          </div>
                          <div className="bank-data">
                            <div className="bank-label nomiee-label">
                              <p>Percentage Allocation</p>
                            </div>
                            <div className="bank-info">
                              <div>
                                <h6>
                                  <b>100 %</b>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={6} lg={3} className="b-layout ">
                        <div className="bank-label nomiee-label bank-data">
                          <p>Nominee Relationship</p>
                        </div>
                        <div
                          className="bank-info"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div>{props.NomineeData.nominee_relation.replace("Siblings","Sibling")}</div>
                        </div>
                      </Col>
                      <Col xs={6} lg={2} className="b-layout ">
                        <div className="bank-label nomiee-label bank-data">
                          <p>Date of Birth</p>
                        </div>
                        <div className="bank-info">
                          <div>
                            {moment(props.NomineeData.nominee_dob).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        lg={3}
                        className={`b-layout ${
                          props.NomineeData.nominee_guardian_name == null || props.NomineeData.nominee_guardian_name == ""
                            ? "d-none"
                            : ""
                        }`}
                      >
                        <div className="bank-label nomiee-label bank-data">
                          <p>Parent/Guardian full name </p>
                        </div>
                        <div
                          className="bank-info"
                          style={{ textTransform: "capitalize" }}
                        >
                          <div>{props.NomineeData.nominee_guardian_name !="" || props.NomineeData.nominee_guardian_name != null?props.NomineeData.nominee_guardian_name:""}</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="mobileNomineeList">
            <div className="Bank-details">
              <div className="d-flex">
                <div className="sm:w-100">
                  <div>
                    <img width="30" src={process.env.REACT_APP_STATIC_URL + "media/DMF/female.png"} />
                  </div>
                  <span>
                    <div className="bank-info Nominee-name mt-1">
                      <div>{props.NomineeData.nomineName}</div>
                    </div>
                  </span>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="lg:mt-2"
                  >
                    <div className="Edit-nominee" style={{}}>
                      <RiEditBoxLine
                        style={{
                          fontSize: "20px",
                          color: "rgb(36, 167, 221)",
                        }}
                        onClick={EditnomineeData}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mt-2"
                style={{
                  borderBottom: "1px solid #f3f3f3",
                }}
              ></div>
              <div className="row">
                <div
                  className="col-4"
                  style={{
                    lineHeight: "6px",
                  }}
                >
                  <div className=" nomiee-label">
                    <p>Percentage Allocation</p>
                  </div>
                  <div className="bank-info">
                    <div>{props.NomineeData.nominee_applicable}</div>
                  </div>
                </div>
                <div
                  className="col-4"
                  style={{
                    lineHeight: "6px",
                  }}
                >
                  <div className=" nomiee-label ">
                    <p>Nominee Relationship</p>
                  </div>
                  <div className="bank-info">
                    <div>{props.NomineeData.NomineeRelation}</div>
                  </div>
                </div>
                <div
                  className="col-4"
                  style={{
                    lineHeight: "6px",
                  }}
                >
                  <div className=" nomiee-label">
                    <p>Date of Birth</p>
                  </div>
                  <div className="bank-info nomineeDOB">
                    <div>
                      {moment(props.NomineeData.nominee_dob).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        backdrop="static"
        className="NomineeModal white-modal"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className="d-flex justify-center p-3">
          <div>
            {" "}
            <FintooBackButton onClick={handleClose} />
          </div>
          <div
            className="DeleteBank text-center pb-3 w-100"
            style={{
              borderBottom: "1px solid #eeee",
            }}
          ></div>
        </div>
        <div>
          <div>
            <div className="p-3">
              <div className="modal-whitepopup-box-item  border-top-0 text-center">
                <p>Sent to</p>
                <p>XXXXXXXX{mobile}</p>
              </div>
              <div className="d-flex justify-center align-items-center">
                <center>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    style={{
                      width: "8%",
                    }}
                    autoFocus
                    className="rounded rounded-otp"
                    OTPLength={5}
                    otpType="number"
                    disabled={false}
                  />
                </center>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #eeee",
                }}
                className="text-center p-3 grey-color"
              >
                {count == 0 && (
                  <p
                    className="pointer blue-color"
                    onClick={() => {
                      randomOTP.current =
                        Math.floor(Math.random() * 90000) + 10000;
                      fetchMail();
                      fetchSms();
                      startTimer();
                    }}
                  >
                    Resend OTP
                  </p>
                )}

                {count > 0 && (
                  <p>
                    Expire in &nbsp;
                    <strong>
                      {moment().startOf("day").seconds(count).format("mm:ss")}
                    </strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        backdrop="static"
        className="NomineeModal white-modal"
        centered
        show={showdata}
        onHide={() => handleClosedata()}
      >
        <div className="d-flex justify-center p-3">
          <div>
            {" "}
            <FintooBackButton onClick={() => handleClosedata()} />
          </div>
          <div
            className="DeleteBank text-center pb-3 w-100"
            style={{
              borderBottom: "1px solid #eeee",
            }}
          >
            <div
              style={{
                fontWeight: "500",
              }}
            >
              {" "}
              Validate changes
            </div>
            <div
              className="pt-2"
              style={{
                fontWeight: "600",
              }}
            >
              Enter OTP
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="p-3">
              <div className="modal-whitepopup-box-item  border-top-0 text-center">
                <p>Sent to</p>
                <p>XXXXXXXX{mobile}</p>
              </div>
              <div className="d-flex justify-center align-items-center">
                <center>
                  <OTPInput
                    value={uOTP}
                    onChange={updatesetOTP}
                    style={{
                      width: "8%",
                    }}
                    autoFocus
                    className="rounded rounded-otp"
                    OTPLength={5}
                    otpType="number"
                    disabled={false}
                  />
                </center>
              </div>

              <div
                style={{
                  borderBottom: "1px solid #eeee",
                }}
                className="text-center p-3 grey-color"
              >
                {count == 0 && (
                  <p
                    className="pointer blue-color"
                    onClick={() => {
                      randomOTP.current =
                        Math.floor(Math.random() * 90000) + 10000;
                      startTimer();
                      fetchMail();
                      fetchSms();
                    }}
                  >
                    Resend OTP
                  </p>
                )}

                {count > 0 && (
                  <p>
                    Expire in &nbsp;
                    <strong>
                      {moment().startOf("day").seconds(count).format("mm:ss")}
                    </strong>
                  </p>
                )}
              </div>
            </div>
            <div
              className="mt-3  OTpConfirm"
              onClick={() => {
                if (GeneratedSmsOTP != uOTP && generatedemailotp != uOTP) {
                  dispatch({
                    type: "RENDER_TOAST",
                    payload: { message: "INVALID OTP", type: "error" },
                  });
                  return;
                }
                updatenomineedetails();
              }}
            >
              Continue
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NomineeList;
